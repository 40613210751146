import React, { useEffect, useState, useRef } from 'react';
import { doTableRequest } from '../../hooks/useTables';
import { toast, showConfirm, showPrompt, showDelete } from '../../message';
import {
  Typography as T,
  Paper,
  Checkbox,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Link,
  IconButton,
  Popover,
  TextField,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

function initializePermissions(token, database) {
  const permissions = {};
  const create = token.permissions.create || [];
  const read = token.permissions.read || [];
  const update = token.permissions.update || [];
  const del = token.permissions.delete || [];
  database.tables.forEach(table => {
    permissions[table.id] = {};
    permissions[table.id].name = table.name;
    permissions[table.id].create = create === true || create.includes(table.id);
    permissions[table.id].read = read === true || read.includes(table.id);
    permissions[table.id].update = update === true || update.includes(table.id);
    permissions[table.id].delete = del === true || del.includes(table.id);
  });

  const allCreate = create === true ? true : (create.length === 0 ? false : undefined);
  const allRead = read === true ? true : (read.length === 0 ? false : undefined);
  const allUpdate = update === true ? true : (update.length === 0 ? false : undefined);
  const allDelete = del === true ? true : (del.length === 0 ? false : undefined);

  return {
    permissions,
    allCreate,
    allRead,
    allUpdate,
    allDelete,
  };
}

/**
 * @param {object} props
 * @param {object} props.database
 * @param {object} props.token
 * @param {function} props.refetch
 */
export default function APIToken(props) {
  const [permissions, setPermissions] = useState(() => initializePermissions(props.token, props.database));
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showKey, setShowKey] = useState(false);
  const lastUpdate = useRef(0);


  useEffect(() => {
    setPermissions(initializePermissions(props.token, props.database));
  }, [props.token, props.database]);

  async function sendUpdateRequest(tokenId, permissions) {
    const data = {};
    ['create', 'read', 'update', 'delete'].forEach(operation => {
      const allKey = 'all' + operation[0].toUpperCase() + operation.slice(1);
      if (permissions[allKey]) {
        data[operation] = true;
      } else {
        const whitelisted = Object.entries(permissions.permissions).filter(([_, value]) => value[operation]).map(([key, _]) => key);
        if (whitelisted.length === 0) {
          data[operation] = false;
        } else {
          data[operation] = whitelisted;
        }
      }
    });
    lastUpdate.current++;
    const updateNumber = lastUpdate.current;
    try {
      await doTableRequest(`database/tokens/${tokenId}/`, 'PATCH', { permissions: data });
    } catch {
      // We optimistically display new permissions in the UI and wait for the request to
      // backend to finish. Once it finishes we need to refetch the token to make
      // sure that the data is fresh (it might be required to revert the permissions in the UI).
      if (updateNumber === lastUpdate.current) {
        props.refetch();
      }
    }
  }

  function handleToggleAll(operation, tokenId) {
    const allKey = 'all' + operation[0].toUpperCase() + operation.slice(1);
    setPermissions(prevPermissions => {
      const result = JSON.parse(JSON.stringify(prevPermissions));
      const newValue = !prevPermissions[allKey];
      props.database.tables.forEach(table => {
        result.permissions[table.id][operation] = newValue;
      });
      result[allKey] = newValue;
      sendUpdateRequest(tokenId, result);
      return result;
    });

  }

  function handleToggle(operation, tableId, tokenId) {
    const allKey = 'all' + operation[0].toUpperCase() + operation.slice(1);
    setPermissions(prevPermissions => {
      const result = JSON.parse(JSON.stringify(prevPermissions));
      const newValue = !result.permissions[tableId][operation];
      result.permissions[tableId][operation] = newValue;
      result[allKey] =
        Object.values(result.permissions).every((value) => value[operation]) ?
          true :
          Object.values(result.permissions).every((value) => !value[operation]) ? false : undefined;
      sendUpdateRequest(tokenId, result);
      return result;
    });
  }

  function handleClick(event, token) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function onGenerateNewToken() {
    const tokenId = props.token.id;
    showConfirm({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Generate',
      intent: 'primary',
      contents: (
        <T style={{ maxWidth: 350 }}>
          This will generate a new key for the token. The old key will no longer work. Do you want to proceed?
        </T>
      ),
      onConfirm: async () => {
        try {
          await doTableRequest(`database/tokens/${tokenId}/`, 'PATCH', { rotate_key: true });
        } catch (error) {
          toast('Could not generate new API key: ' + error, {
            duration: 5000,
            intent: 'danger'
          });
          return;
        }

        await props.refetch();

        toast('New API key generated.', {
          intent: 'success'
        });
      },
      onCancel: () => { }
    });
  }

  async function onRenameToken() {
    const tokenId = props.token.id;
    setAnchorEl(null);
    showPrompt({
      contents: <T>Enter the new name:</T>,
      confirmButtonText: 'Rename',
      placeholder: props.token.name,
      onConfirm: async (newName) => {
        try {
          await doTableRequest(`database/tokens/${tokenId}/`, 'PATCH', { name: newName });
        } catch (error) {
          toast('Could not rename token: ' + error, {
            duration: 5000,
            intent: 'danger'
          });
          return;
        }

        await props.refetch();

        toast('Token renamed.', {
          intent: 'success'
        });
      }
    });
  }

  async function onDeleteToken() {
    const tokenId = props.token.id;
    setAnchorEl(null);
    showDelete({
      item: props.token.name,
      onDelete: async () => {
        try {
          const deleteResponse = await doTableRequest(`database/tokens/${tokenId}/`, 'DELETE');
          if (deleteResponse?.error) {
            toast(`Error deleting token: ${deleteResponse.detail}`, { duration: 6000, intent: 'danger' });
            return;
          }
        } catch {
          toast('An error occurred deleting the token.', {
            duration: 5000,
            intent: 'danger'
          });
          return;
        }

        await props.refetch();

        toast('Token deleted.', {
          intent: 'success'
        });
      },
    });
  }

  const popoverOpen = !!anchorEl;

  return <Paper elevation={3} sx={{ m: 3 }}>
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Grid container alignItems="center">
                <Grid item xs={9}>
                  <T noWrap sx={{ width: 120 }}>
                    {props.token.name}
                  </T>
                </Grid>
                <Grid item xs={1}>
                  <IconButton size="small" onClick={(event) => handleClick(event, props.token)}>
                    <MoreHorizOutlinedIcon fontSize="small" />
                  </IconButton>
                  <Popover
                    open={popoverOpen}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <List component="nav" dense>
                      <ListItemButton onClick={onGenerateNewToken}>
                        <ListItemIcon>
                          <CachedOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Generate new API key" />
                      </ListItemButton>
                      <ListItemButton onClick={onRenameToken}>
                        <ListItemIcon>
                          <EditOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Rename" />
                      </ListItemButton>
                      <ListItemButton onClick={onDeleteToken}>
                        <ListItemIcon>
                          <DeleteOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Delete" />
                      </ListItemButton>
                    </List>
                  </Popover>
                </Grid>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 0.5, mt: 0.5 }}>
                <Grid item xs={8}>
                  <TextField
                    type={showKey ? 'text' : 'password'}
                    value={props.token.key}
                    size="small"
                    hiddenLabel
                    disabled
                    sx={{ width: '260px' }}
                    InputProps={{ style: { fontSize: 12 } }}
                  >
                  </TextField>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    size="small"
                    onClick={() => setShowKey(prev => !prev)}
                  >
                    {showKey ? <VisibilityOutlinedIcon fontSize="small" /> : <VisibilityOffOutlinedIcon fontSize="small" />}
                  </IconButton>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      navigator.clipboard.writeText(props.token.key);
                      toast('Copied!', {
                        intent: 'success',
                        duration: 700,
                      });
                    }}
                  >
                    <ContentCopyOutlinedIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
              <Link
                component="button"
                onClick={() => setOpen(oldValue => !oldValue)}
              >
                {open ? 'Hide tables' : 'Show tables'}
              </Link>
            </TableCell>
            <TableCell align="center">
              <T variant="body2">Create</T>
              <Checkbox
                checked={permissions.allCreate !== false}
                indeterminate={permissions.allCreate === undefined}
                color={permissions.allCreate === undefined ? 'secondary' : 'primary'}
                onChange={() => handleToggleAll('create', props.token.id)} />
            </TableCell>
            <TableCell align="center">
              <T variant="body2">Read</T>
              <Checkbox
                checked={permissions.allRead !== false}
                indeterminate={permissions.allRead === undefined}
                color={permissions.allRead === undefined ? 'secondary' : 'primary'}
                onChange={() => handleToggleAll('read', props.token.id)} />
            </TableCell>
            <TableCell align="center">
              <T variant="body2">Update</T>
              <Checkbox
                checked={permissions.allUpdate !== false}
                indeterminate={permissions.allUpdate === undefined}
                color={permissions.allUpdate === undefined ? 'secondary' : 'primary'}
                onChange={() => handleToggleAll('update', props.token.id)} />
            </TableCell>
            <TableCell align="center">
              <T variant="body2">Delete</T>
              <Checkbox
                checked={permissions.allDelete !== false}
                indeterminate={permissions.allDelete === undefined}
                color={permissions.allDelete === undefined ? 'secondary' : 'primary'}
                onChange={() => handleToggleAll('delete', props.token.id)} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(permissions.permissions).filter(_ => open).map(([tableId, value]) => {
            return <TableRow key={tableId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>
                {value.name}
              </TableCell>
              <TableCell align="center">
                <Checkbox
                  checked={value.create}
                  onChange={() => handleToggle('create', tableId, props.token.id)} />
              </TableCell>
              <TableCell align="center">
                <Checkbox
                  checked={value.read}
                  onChange={() => handleToggle('read', tableId, props.token.id)} />
              </TableCell>
              <TableCell align="center">
                <Checkbox
                  checked={value.update}
                  onChange={() => handleToggle('update', tableId, props.token.id)} />
              </TableCell>
              <TableCell align="center">
                <Checkbox
                  checked={value.delete}
                  onChange={() => handleToggle('delete', tableId, props.token.id)} />
              </TableCell>
            </TableRow>;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>;
}