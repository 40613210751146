import { Link, useHistory } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import { showAirtableImportDialog, showTemplatesDialog } from './database_utilities';
import T from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DatabaseChipIcon from './DatabaseChipIcon';
import { Grow, LinearProgress } from '@mui/material';
import { toast } from '../../message';
import { doSafeTableRequest } from '../../hooks/useTables';
import { pollJob } from './job_polling_util';
import { storage } from '../../utilities';
import { usersSettingsRef } from '@store';

/** @type {{id: string, name: string, icon: string}[]} **/
const STARTER_TEMPLATES = [
  {
    'id': '2khqj5X5YiVD72lYY3aIBZ',
    'name': 'Project Tracker',
    'icon': '📈️',
  },
  {
    'id': '3KKnh5r1P0JgPsE0UllmiT',
    'name': 'CRM',
    'icon': '💼',
  },
  {
    'id': '6Ler1Hybr1HzC88KNFnhvu',
    'name': 'Personal Task Manager',
    'icon': '📋',
  },
];

/**
 * @param {object} props
 * @param {() => void} props.onCreate
 * @param {() => void} props.onImportStarted
 * @param {() => void} props.onImportError
 */
export default function WelcomeToDataBlaze(props) {
  const { push: navigate } = useHistory();
  const containerRef = useRef(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const renderText = (text) => <T variant="button" color="textSecondary" style={{ fontWeight: 'normal' }}>
    {text}
  </T>;

  async function favoriteAndNavigate(databaseId) {
    await storage.update(usersSettingsRef, {
      [`options.databases.${databaseId}.favorite`]: true,
      [`options.databases.${databaseId}.order`]: 1,
    });
    navigate(`/space/${databaseId}`);
  }

  return <div
    style={{
      flexDirection: 'column',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 600,
      }}
      ref={containerRef}
    >
      {!!selectedTemplate ? null : <T variant="h4" paragraph>Welcome to Data Blaze</T>}
      {!!selectedTemplate ? null : <T variant="h5" paragraph sx={{ mb: 6 }}>Get started with one of these template spaces.</T>}
    
      {!!selectedTemplate && <Grow in={!!selectedTemplate}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          {!!selectedTemplate && <TemplateChip name={selectedTemplate.name} icon={selectedTemplate.icon} />}
          <LinearProgress variant="indeterminate" style={{ width: 400, marginTop: 16 }} />
          <T variant="button" color="textSecondary" style={{ fontWeight: 'normal', marginTop: 8 }}>
        Please wait while we create your space...
          </T>
        </div>
      </Grow>}
      {!selectedTemplate && <>
        <div className="database-grid" style={{
          width: '100%',
          gridGap: 30,
          marginTop: 8,
        }}>
          {STARTER_TEMPLATES.map(template => (
            <TemplateChip
              key={template.id}
              name={template.name}
              icon={template.icon}
              onClick={async() => {
                try {
                  props.onImportStarted();
                  setSelectedTemplate(template);
                  const response = await doSafeTableRequest(`templates/install/${template.id}/async/`,
                    'POST',
                    {},
                    { noNotify: true },
                  );
                  if (response.error) {
                    toast(response.detail, {
                      duration: 6000,
                      intent: 'danger'
                    });
                    return;
                  }

                  const jobResponse = await pollJob(response.id);
                  await favoriteAndNavigate(jobResponse.installed_applications[0].id);
                } catch (error) {
                  props.onImportError();
                  toast(error.message, {
                    duration: 6000,
                    intent: 'danger'
                  });
                } finally {
                  setSelectedTemplate(null);
                }
              }}

            />
          ))}
          <TemplateChip
            name="Browse more templates..."
            icon="+"
            onClick={() => {
              showTemplatesDialog({
                onCreated: ({ databaseId }) => favoriteAndNavigate(databaseId),
              });
            }}
          />
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: 48,
        }}>
          {renderText('Alternatively, you can')}
          <Button
            onClick={props.onCreate}
            data-testid="create-new-space-link"
          >create an empty space</Button>
          {renderText('or')}
          <Button
            onClick={() => {
              showAirtableImportDialog({
                openImportedButtonLabel: 'Open imported space',
                onCreated: ({ databaseId }) => favoriteAndNavigate(databaseId),
              });
            }}
          >
          import from Airtable
          </Button>
          {renderText('.')}
        </div>
      </>}
    </div>
  </div>
  ;
}


/**
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.icon
 * @param {() => void=} props.onClick
 */
function TemplateChip(props) {
  return <div
    className="database-chip"
    style={{
      position: 'relative'
    }}
  >
    <Link
      className="space-chip-link"
      to="#"
      onClick={props.onClick}
      style={{
        textAlign: 'center',
        textDecoration: 'none',
        color: 'inherit'
      }}
    >
      <DatabaseChipIcon icon={props.icon} isShared={false} isPublished={false}/>
      <T variant="subtitle1" style={{
        marginTop: 12,
        wordBreak: 'break-word',
      }}>{props.name}</T>
    </Link>
  </div>;
}
