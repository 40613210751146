import React, { useEffect, useState } from 'react';
import { TABLES_FRONT_END_DOMAIN } from '../../hooks/useTables';
import IconButton from '@mui/material/IconButton';
import T from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/GridOn';
import Tooltip from '@mui/material/Tooltip';
import { Helmet } from 'react-helmet-async';
import { useTypedSelector, useTypedSelectorShallowEquals } from '../../hooks';
import { useHistory } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import { AlertTheme } from '../Theme/MUITheme';
import { storage } from '../../utilities';
import { usersSettingsRef } from '@store';
import { LinearProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import SnippetPreviewPanel from '../Snippet/SnippetPreviewPanel';
import Shortcut from '../Shortcut/Shortcut';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import SnippetFolderOutlinedIcon from '@mui/icons-material/SnippetFolderOutlined';
import './database-copy.css';
import Grid from '@mui/material/Grid';
import { EmptyState } from '../EmptyState/EmptyState';
import { compressDelta } from '../../delta_proto/DeltaProto';
import useAppNavigate from '../../hooks/useAppNavigate';
import AppLink from '../Utilities/AppLink';
import { saveBundleOrSpace } from './database_utilities';
import { orgLoadedIfNeeded } from '../../flags.js';


const MAIN_BORDER_RADIUS = 9;
const BORDER_RADIUS_TOP = {
  borderTopLeftRadius: MAIN_BORDER_RADIUS,
  borderTopRightRadius: MAIN_BORDER_RADIUS,
};
const BUNDLE_TIMEOUT = 60 * 60 * 1000; // one-hour


function SavingDraftDialog() {
  return (
    <Dialog
      open
      maxWidth="lg"
    >
      <DialogContent
        sx={{
          minWidth: {
            xs: 'min(calc(100% - 40px), 370px)',
            sm: 370,
          },
          paddingX: 4,
          paddingY: 8,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <LinearProgress sx={{ width: '100%', marginBottom: 4 }}/>
          <span>Please wait while saving your draft, it may take a few moments…</span>
        </div>
      </DialogContent>
    </Dialog>
  );
}

/**
 * @param {object} props
 * @param {"space" | "bundle" } props.mode
 * @param {import('./DatabasePublicSpaceEmbed').BundleInfo=} props.bundleData
 */
export default function DatabaseCopy(props) {
  const { mode } = props;
  const { replace } = useHistory();
  const navigate = useAppNavigate();
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const favorites = useTypedSelectorShallowEquals((state) => state.userState.settingsLoaded ? (state.userState?.options?.databases || {}) : null);
  const orgLoadedCheck = useTypedSelector((state) => orgLoadedIfNeeded(state));
  const isBundle = mode === 'bundle';

  let maxOrder = 0;
  if (favorites) {
    maxOrder = Math.max(...[0].concat(Object.values(favorites).filter(db => db.favorite).map(db => db.order || 0)));
  }

  useEffect(() => {
    cleanupObsoleteBundles();
    setLoading(!!bundle && !!bundle.applicationId);

    const listener = async (event) => {
      if (event.origin !== TABLES_FRONT_END_DOMAIN) {
        return;
      }

      const type = event.data.type;
      if (type === 'template_loaded') {
        setLoading(false);
      } else if (type === 'not_found') {
        setNotFound(true);
        setLoading(false);
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);

    // eslint-disable-next-line
  }, []);

  const params = new URLSearchParams(window.location.search);
  /** @type {import('./DatabasePublicSpaceEmbed').BundleInfo} */
  let bundle;
  let templateId;
  let spaceId;
  let bundleHash;
  if (mode === 'space') {
    templateId = params.get('template');
    spaceId = params.get('space');
    if (!templateId && !spaceId) {
      return renderNotFound();
    }
  } else if (!!props.bundleData) {
    bundle = props.bundleData;
    spaceId = bundle.applicationId;
  } else {
    bundleHash = params.get('bundle');
    if (!bundleHash) {
      return renderNotFound();
    }
  }

  if (bundleHash) {
    try {
      const bundleKey = `bundle-${bundleHash}`;
      bundle = JSON.parse(localStorage.getItem(bundleKey));
      spaceId = bundle.applicationId;
      localStorage.setItem(bundleKey, JSON.stringify({
        ...bundle,
        createdAt: new Date().toISOString(),
      }));
    } catch (_) {
      return renderNotFound();
    }
  }

  let innerUrl;
  if (templateId) {
    innerUrl = `${TABLES_FRONT_END_DOMAIN}/public/templates/${templateId}?copy=false`;
  } else {
    innerUrl = `${TABLES_FRONT_END_DOMAIN}/public/application/${spaceId}?copy=false`;
  }

  function cleanupObsoleteBundles() {
    const bundlesToDelete = [];
    for (let key in localStorage) {
      if (key.startsWith('bundle-')) {
        try {
          const bundle = JSON.parse(localStorage.getItem(key));
          if (new Date().getTime() - new Date(bundle.createdAt).getTime() > BUNDLE_TIMEOUT) {
            bundlesToDelete.push(key);
          }
        } catch (_) {
          // in case user has manually played with the value, let's not fail
          bundlesToDelete.push(key);
        }
      }
    }

    bundlesToDelete.forEach(key => localStorage.removeItem(key));
  }

  async function handleSave() {
    setSaving(true);
    const { newApplicationId, folderUrl } = await saveBundleOrSpace(bundle, spaceId, templateId);

    if (folderUrl) {
      window.open(folderUrl, !!newApplicationId ? '_blank' : '_self');
    }

    if (newApplicationId) {
      await storage.update(usersSettingsRef, {
        [`options.databases.${newApplicationId}.favorite`]: true,
        [`options.databases.${newApplicationId}.order`]: maxOrder + 1
      });

      replace(`/space/${newApplicationId}`);
    }

    setSaving(false);
  }

  function renderFrame() {
    return <iframe
      title="Template"
      src={innerUrl}
      style={{
        flexGrow: 1,
        display: loading ? 'none' : 'inherit',
        border: '1px solid #cccccd',
        overflow: 'hidden',
        width: isBundle ? '100%' : 'auto',
        height: isBundle ? '100%' : 'auto',
        margin: isBundle ? 0 : '0 16px 16px',
        ...BORDER_RADIUS_TOP,
      }}
    />;
  }

  function renderNotFound() {
    return <EmptyState icon="MISSING" title={`Could not load ${mode}`} description={`That ${mode} does not exist`} />;
  }

  function renderBundle() {
    if (bundle.applicationId) {
      return <div style={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}>
        <Grid container>
          <Grid item xs={12} md={6} style={{
            padding: '0 7px 15px 15px',
          }}>
            {renderFrame()}
          </Grid>
          <Grid item xs={12} md={6} style={{
            padding: '0 15px 15px 7px',
          }}>
            {renderSnippets()}
          </Grid>
        </Grid>
      </div>;
    } else {
      return <div
        style={{
          height: '100%',
          padding: '0 16px 16px',
        }}
      >
        {renderSnippets()}
      </div>;
    }
  }

  function renderSnippets() {
    if (loading) {
      return;
    }

    return <div style={{
      border: '1px solid #cccccd',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      ...BORDER_RADIUS_TOP,
    }}>
      <div style={{
        padding: '15px 15px 4px',
        fontSize: '0.83em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        borderBottom: '1px solid #ddd',
        display: 'flex',
        backgroundColor: '#e04f40',
        color: '#fff',
      }}>
        <span><SnippetFolderOutlinedIcon sx={{ marginRight: '5px', fontSize: '18px' }} /></span>
        <span style={{ fontWeight: 'bold' }}>{bundle.name}</span>
      </div>

      <div className="bundle-snippets" style={{
        flexGrow: 1,
        position: 'relative',
      }}>
        <div style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          minHeight: 0,
          overflowY: 'auto',
          padding: '0 12px 24px'
        }}>
          {bundle.snippets.map((snippet, index) => (
            <div key={`snippetPreview${index}`} style={{ margin: '18px 0' }}>
              <div style={{ display: 'flex', marginBottom: 4 }}>
                <TextSnippetOutlinedIcon fontSize="small" sx={{ marginRight: '2px' }} />
                <T variant="subtitle2" sx={{ flexGrow: 1 }}>{snippet.name}</T>
                <Shortcut
                  shortcut={snippet.shortcut}
                />
              </div>
              <SnippetPreviewPanel
                snippet={{ ...snippet, id: `${index}` }}
                snippetId={`${index}`}
                delta={snippet.delta}
                connected={snippet.connected}
                findSnippet={(name) => {
                  const nameUpper = name.toUpperCase();
                  const snippet = bundle.snippets.find(snippet => snippet.shortcut?.toUpperCase() === nameUpper);
                  if (snippet) {
                    return {
                      delta: compressDelta(snippet.delta),
                    };
                  }

                  return null;
                }}
                hideProMessage
                hideShortcut
              />
            </div>
          ))}
        </div>
      </div>
    </div>;
  }

  return <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh'
    }}>
    <Helmet defer={false}>
      <title>Draft {mode}</title>
    </Helmet>
    {!loading && !notFound && <>
      <AppBar
        position="static"
        color="secondary"
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: 48,
        }}
      >
        <div
          style={{
            display: 'flex',
            height: 48,
            alignItems: 'center',
          }}
        >
          <Tooltip title="View all spaces">
            <IconButton
              to="/spaces"
              component={AppLink}
              appType="DATA"
              color="inherit"
              sx={{
                mx: 1,
                opacity: .7
              }}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <T variant="h6">Draft {mode}</T>
        </div>
      </AppBar>
      <AlertTheme>
        <div style={{
          overflow: 'hidden',
          margin: 16,
          flexShrink: 0,
        }}>
          <AppBar position="static" elevation={0} style={{
            borderRadius: 8
          }}>
            <Toolbar style={{
              display: 'flex'
            }}>
              <T variant="body2" sx={{ padding: 1, paddingRight: 4, flex: 1 }}>
                {bundle ?
                  (spaceId ?
                    'This is a draft bundle, you can review and save. When you save it, it will copy the space to Data Blaze, and create a folder in Text Blaze with all the listed snippets connected to your newly created space.' :
                    'This is a draft bundle, you can review and save. When you save it, it will create a folder in Text Blaze with all the listed snippets.'
                  ) :
                  `This is a draft ${mode} you can review and save. If you navigate away from this page without saving, the draft will be discarded.`}
              </T>
              <Button color="secondary" sx={{ marginRight: 2 }} onClick={() => navigate('/spaces', 'DATA')}>Discard</Button>
              <Button variant="outlined" color="secondary" disabled={!orgLoadedCheck || saving} onClick={handleSave}>
                Save
              </Button>
            </Toolbar>
          </AppBar>
        </div>
      </AlertTheme>
    </>}
    { loading && <LinearProgress variant="indeterminate" /> }
    { notFound ? renderNotFound() : (isBundle ? renderBundle() : renderFrame()) }
    { saving && <SavingDraftDialog /> }
  </div>;
}
