import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DatabaseList from './DatabaseList';
import { createNewDatabase, showSnapshotsDialog } from './database_utilities';
import DatabaseChip from './DatabaseChip';
import { DatabaseChipMenu } from './DatabaseChipMenu';
import SpacesSearch from './SpacesSearch';
import DBWalkthrough from '../Walkthrough/DBWalkthrough';
import DatabaseListItem from './DatabaseListItem';
import { storage } from '../../utilities';
import { usersSettingsRef } from '@store';
import { useTypedSelector } from '../../hooks';
import WelcomeToDataBlaze from './WelcomeToDataBlaze';
import { toast } from '../../messageToast';
import { doSafeTableRequest } from '../../hooks/useTables';


/**
 * @param {import('@store').OptionsViewSettings} spacesSettings
 */
function setUserSpacingSettings(spacesSettings) {
  storage.update(usersSettingsRef, {
    'options.spaces_settings': spacesSettings,
  });
}

export default function SpacesList() {
  const { push: navigate } = useHistory();
  let isEnterpriseOwner = useTypedSelector((state) => state.userState.org?.type === 'owner' && !!state.orgState?.org?.readonly?.is_enterprise);
  const [additionalData, setAdditionalData] = useState(null);

  /**
  * @type {import('@store').OptionsViewSettings}
  */
  const spacesSettings = useTypedSelector((state) =>
    state.userState.settingsLoaded && state.userState?.options && state.userState?.options.spaces_settings
      ? state.userState?.options.spaces_settings
      : { view: null, list_order_by: null }
  );

  useEffect(() => {
    if (!isEnterpriseOwner) {
      return;
    }
    doSafeTableRequest('applications/logs/', 'GET').then(d => {
      setAdditionalData([{
        ...d,
        user_permission: 'owner'
      }]);
    }).catch((err) => {
      if (err.responseStatus === 404) {
        return;
      }
      toast('Failed to fetch audit logs information - ' + err.message, {
        intent: 'danger'
      });
      console.error(err);
    });
  }, [isEnterpriseOwner]);

  const viewMode = spacesSettings.view || 'grid';

  function getRenderItemProps(data, { key, database, refetch }) {
    /** @type {SpaceObjectType} */
    const tableId = (database).tables?.[0]?.id;

    return {
      link: `/space/${database.id}/${tableId ? `table/${tableId}/` : ''}`,
      updateIconEndpoint: `applications/${database.id}/`,
      itemName: 'Space',
      favoritesOptionKey: 'databases',
      renderMenu: (menuProps) => (
        <DatabaseChipMenu
          database={/** @type {SpaceObjectType} */ (database)}
          refetch={refetch}
          findUnusedSpaceName={(prefix) => {
            const commonNames = data
              .map((space) => space.name)
              .filter((name) => name.startsWith(prefix));
            let unusedIndex = 1;
            let unusedName;
            do {
              unusedName = `${prefix} ${++unusedIndex}`;
            } while (commonNames.includes(unusedName));
            return unusedName;
          }}
          openSnapshots={(database) => {
            showSnapshotsDialog({
              databaseNames: data.map((db) => db.name),
              database: database,
              onRestored: () => {
                refetch();
              },
            });
          }}
          {...menuProps}
        />
      ),
      key,
      database,
      refetch,
    };
  }

  return <DatabaseList
    favoritesOptionKey="databases"
    apiEndpoint="applications/shared/"
    wsPage="spaces"
    wsRefreshEvents={[
      'application_created',
      'application_updated',
      'application_deleted'
    ]}
    labels={{
      productName: 'Data Blaze',
      itemName: 'space',
      itemNamePlural: 'spaces',
    }}
    onCreate={() => createNewDatabase({
      createDefaultTable: true,
      openImportedButtonLabel: 'Open imported space',
    }).then(({ databaseId }) => {
      navigate('/space/' + databaseId);
    })}
    renderItem={(data, { key,  database, refetch }) => (
      viewMode === 'grid'
        ? <DatabaseChip {...getRenderItemProps(data, { key,  database, refetch })}/>
        : <DatabaseListItem {...getRenderItemProps(data, { key,  database, refetch })} />
    )
    }
    renderSearch={(data, onClose) => <SpacesSearch
      spaces={data}
      onClose={onClose}
      itemTypePlural="spaces"
      selectFn={(spaceId) => navigate(`/space/${spaceId}`)}
    />}
    walkthrough={DBWalkthrough}
    setUserSettings={setUserSpacingSettings}
    userSettings={spacesSettings}
    renderEmptyComponent={(onCreate, onImportStarted, onImportError) => <WelcomeToDataBlaze
      onCreate={onCreate}
      onImportStarted={onImportStarted}
      onImportError={onImportError}
    />}
    additionalData={additionalData}
  />;
}
