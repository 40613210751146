import React, { useState } from 'react';
import { Button } from '@mui/material';
import ShareEntity from '../Group/ShareEntity';
import { userPermission } from '../../auth';
import { Link, useHistory } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import AsyncButton from '../AsyncButton/AsyncButton';
import { useTypedSelectorShallowEquals } from '../../hooks';
import { limitationsState } from '../Version/limitations';
import { doSafeTableRequest } from '../../hooks/useTables';
import T from '@mui/material/Typography';
import { toast } from '../../message';
import { isBlaze, orgPref, PAGE_API_PREFIX } from '../../flags';
import { DatabasePublishedControls, DatabasePublishControl } from './DatabasePublishedControls';


/** @typedef {{associated_org_id?: string, permissions?: PermissionsType}} UpdateEntityData */

/**
 * @param {object} props
 * @param {SpaceObjectType|SiteObjectType} props.database
 * @param {function=} props.onClose
 * @param {object=} props.buttonProps
 * @param {function} props.refetch
 * @param {'database'|'site'} props.entityType
 */
export default function DatabaseShareDialog(props) {
  const {
    maxUsersPerGroup,
    canUpgradeMaxUsersPerGroup,
    readonlyLoaded,
    emailVerified,
    orgId,
    canPublic
  } = useTypedSelectorShallowEquals((state) => {
      
    let limits = limitationsState(state);
    
    return {
      orgId: state.userState.org ? state.userState.org.id : null,
      emailVerified: state.userState.emailVerified,
      readonlyLoaded: state.userState.readonlyLoaded,
      maxUsersPerGroup: limits.MAX_USERS_PER_GROUP,
      canUpgradeMaxUsersPerGroup: limits.CAN_UPGRADE_MAX_USERS_PER_GROUP,
      // TODO templates library remove isBlaze on full release
      canPublic: isBlaze(state.userState) && !orgPref(state, 'xSharingDisabled')
    };
  });

  const { push: navigate } = useHistory();
  const apiEndpoint = props.entityType === 'database' ? `applications/${props.database.id}/` : `${PAGE_API_PREFIX}/sites/${props.database.id}/`;
  const entityLabel = props.entityType === 'database' ? 'space' : 'site';
  let [isPublic, setIsPublic] = useState(null);

  function permission() {
    if (!props.database) {
      return null;
    }
    return userPermission(props.database);
  }

  function shareCount() {
    if (!props.database || !props.database.permissions) {
      return 0;
    }
    return Object.keys(props.database.permissions).length;
  };

  function showShareCount() {
    // only show to orgs if they are within a hundred of the limits
    return emailVerified && readonlyLoaded
      && (canUpgradeMaxUsersPerGroup || maxUsersPerGroup < shareCount() + 100);
  };

  /**
   * @param {UpdateEntityData} data
   * @param {string} toastMessage
   * @returns {Promise<void>}
   */
  async function updateEntity(data, toastMessage) {
    let error;
    let response;
    try {
      response = await doSafeTableRequest(apiEndpoint,'PATCH', data);
      if (response?.error) {
        // we have the error details, include in the message
        error = new Error(response.detail);
      }
    } catch {
      // we don't have the error details, show the default toast message
      error = new Error(toastMessage);
    }

    if (error) {
      throw error;
    }

    // param response is needed in Page Blaze only to update the site's data in the store
    await props.refetch(response);
  }

  function renderOrgAssociation() {
    if (!props.database.associated_org_id && !orgId) {
      return;
    }
    const getContents = () => {
      /**
       * @type {import('@mui/system').SxProps<import('@mui/material').Theme>}
       */
      const linkSx = {
        p: 0,
        verticalAlign: 'inherit',
        display: 'contents',
        textDecoration: 'underline'
      };
      if (!props.database.associated_org_id) {
        return (
          <span>
            This {entityLabel} is not associated with your organization.&nbsp;
            <AsyncButton sx={linkSx} onClick={async (done) => {
              try {
                await updateEntity({
                  associated_org_id: orgId
                }, 'An error occurred updating organization association.');
              } catch (error) {
                toast(`Error updating organization association: ${error.message}`, { duration: 6000, intent: 'danger' });
              }
              done();
            }}>Associate the {entityLabel} with your organization</AsyncButton>
          </span>
        );
      }
      if (orgId === props.database.associated_org_id) {
        return <span>This {entityLabel} is associated with <Button sx={linkSx} component={Link} to="/business">your organization</Button>. Organization administrators and editors will have access to it.</span>;
      }
      return (
        <span>
          The {entityLabel} is associated with a Text Blaze organization. However you are not part of the organization - you can&nbsp;
          <AsyncButton
            sx={linkSx}
            onClick={async (done) => {
              try {
                await updateEntity({
                  associated_org_id: 'REMOVE'
                }, 'An error occurred updating organization association.');
              } catch (error) {
                toast(`Error updating organization association: ${error.message}`, { duration: 6000, intent: 'danger' });
              }
              done();
            }}
          >remove the {entityLabel} association</AsyncButton>
        </span>
      );
    };
    
    
    return (
      <T
        color="textSecondary"
        sx={{
          display: 'flex',
          mb: 1.25
        }}
        variant="body2"
        component="div"
      >
        <BusinessIcon
          fontSize="small"
          sx={{
            mr: 1.5,
            lineHeight: 1.43
          }}
        />
        <div>
          {getContents()}
        </div>
      </T>
    );
  }


  return <ShareEntity
    open={!!props.onClose}
    hideButton={!!props.onClose}
    onClose={props.onClose}
    entityName={props.database.name}
    entityId={props.database.id}
    entityType={props.entityType}
    entityLabel={entityLabel}
    permission={permission()}
    disabled={false}
    hidden={false}
    closeExtraSharingOptions={isPublic}
    hideGrantIcons
    permissions={props.database.permissions}
    buttonProps={props.buttonProps}
    validateUsersFn={(proposedEntities) => {
      //This change will effect all entities, unlike only emails in previous implementation
      if (proposedEntities.length + shareCount() <= maxUsersPerGroup) {
        return true;
      }
      toast(`You may only share a ${entityLabel} with ` + maxUsersPerGroup + ' users.', {
        duration: 8000,
        intent: 'danger',
        upgrade: canUpgradeMaxUsersPerGroup && 'Upgrade to share with more users.'
      });
      return false;
    }}
    onChange={async (update) => {
      if (update.type === 'SET') {
        // when an error is thrown from this call, it should be propagated and handled by AddUsersBox,
        // otherwise, a false positive success message will be displayed
        await updateEntity({
          permissions: update.data.newGrants
        }, 'An error occurred updating permissions.');
      } else if (update.type === 'UPDATE') {
        let newPermissions = Object.assign({}, props.database.permissions);
        newPermissions[update.data.user] = update.data.grant;
        try {
          await updateEntity({
            permissions: newPermissions
          }, 'An error occurred updating permissions.');
        } catch (error) {
          toast(`Error updating user's permission: ${error.message}`, {
            duration: 6000,
            intent: 'danger'
          });
        }
      } else if (update.type === 'REMOVE') {
        let newPermissions = Object.assign({}, props.database.permissions);
        delete newPermissions[update.data.user];
        try {
          await updateEntity({
            permissions: newPermissions
          }, 'An error occurred updating permissions.');
        } catch (error) {
          toast(`Error removing user: ${error.message}`, {
            duration: 6000,
            intent: 'danger'
          });
        }
      } else {
        throw new Error('Unknown onChange type: ' + update.type);
      }
    }}
    extraSharingOptions={<>
      {renderOrgAssociation()}

      {canPublic && !isPublic && <DatabasePublishControl database={props.database} setIsPublic={setIsPublic} />}
    </>}
    extraElements={canPublic ? <DatabasePublishedControls database={props.database} setIsPublic={setIsPublic} /> : null}
    footer={
      <>
        {showShareCount() && <div style={{ textAlign: 'left', marginTop: 8, marginBottom: 6 }}>
          <T color="textSecondary" variant="body2" style={{ display: 'inline-block' }}>
             Currently using <b>{shareCount()} out of {maxUsersPerGroup}</b> available shares
          </T>
          {canUpgradeMaxUsersPerGroup && (
            <Button
              size="small"
              variant="outlined"
              style={{
                marginLeft: 10,
                marginRight: 8
              }}
              onClick={() => navigate('/upgrade')}
            >Get more</Button>
          )}
        </div>}
      </>
    }
    saveMsg={`New users granted access to the ${entityLabel}.`}
    btnText="Share"
  />;
}