import React, { useEffect, useState } from 'react';
import { useTables } from '../../hooks/useTables';
import { Zoom, Fab, Tooltip, Box, Skeleton, LinearProgress } from '@mui/material';
import DatabaseAdd from '@mui/icons-material/Add';
import './database_list.css';
import { EmptyState } from '../EmptyState/EmptyState';
import T from '@mui/material/Typography';
import { useTypedSelector } from '../../hooks';
import { Helmet } from 'react-helmet-async';
import { useTablesSocket } from '../../hooks/useTablesSocket';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import ListIcon from '@mui/icons-material/List';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { hasNeverBeenOpened } from './database_utilities';
import moment from 'moment';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import GridOnIcon from '@mui/icons-material/GridOn';
import { LightweightVerificationReminder } from '../VerificationReminder/VerificationReminder';
import useHotkey from '../../hotkeys_hook';

/**
 *
 * @param {object} props
 * @param {any[]|null} props.list
 * @param {string} props.title
 * @param {(database) => React.ReactElement} props.renderItem
 */
function RenderByLastOpenedList({ list, title, renderItem }) {
  if (!list || !list.length) {
    return null;
  }

  return (<div className="database-list__last-opened-wrapper">
    <T className="database-list__last-opened-title">{title}</T>
    {list.map((database) => renderItem(database))}
  </div>
  );
}

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.state
 * @param {string} props.value
 * @param {string=} props.className
 * @param {(arg?: string) => void} props.handleOnClick
 * @returns {JSX.Element}
 */
function ListHeader({ children, state, value, handleOnClick, className }) {
  let newState = null;

  if (state === `asc_${value}`) {
    newState = `desc_${value}`;
  } else {
    newState = `asc_${value}`;
  }

  return (
    <Box
      className={`database-list__list-header ${className || ''}`}
      onClick={() => {
        handleOnClick(newState);
      }}
    >
      {(state && state.endsWith(value))
        ? state.startsWith('asc')
          ? <ExpandMoreIcon className="database-list__sort-icon" />
          : <ExpandLessIcon className="database-list__sort-icon" />
        : null
      }

      {children}
    </Box>
  );
}


/**
 * @typedef {object} CommonChipProperties
 * @property {string} key
 * @property {SpaceObjectType|SiteObjectType} database
 * @property {() => Promise<any>} refetch
 */

/**
 * @typedef {object} DatabaseListLabels
 * @property {string} itemName
 * @property {string} itemNamePlural
 * @property {string} productName
 */

/**
 * @param {object} props
 * @param {function():void} props.onCreate
 * @param {(data: SpaceObjectType[]|SiteObjectType[], chipProps: CommonChipProperties) => React.ReactElement} props.renderItem
 * @param {(data: SpaceObjectType[]|SiteObjectType[], onClose: ()=> void) => React.ReactElement} props.renderSearch
 * @param {import('../Walkthrough/DBWalkthrough').default=} props.walkthrough
 * @param {'databases'|'sites'} props.favoritesOptionKey the key used in user options to store the favorite information
 * @param {string} props.apiEndpoint the endpoint used to fetch the data from the backend that will be rendered as chips
 * @param {string} props.wsPage the Baserow's WebSockets page (channel) to subscribe to
 * @param {string[]} props.wsRefreshEvents the WebSockets events that should trigger data refresh
 * @param {DatabaseListLabels} props.labels
 * @param {(settings: import('@store').OptionsViewSettings) => void} props.setUserSettings
 * @param {import('@store').OptionsViewSettings} props.userSettings
 * @param {(onCreate: () => void, onImportStarted: () => void, onImportError: () => void) => React.ReactNode} props.renderEmptyComponent
 * @param {object[]=} props.additionalData
 */
export default function DatabaseList(props) {
  let favorites = useTypedSelector((state) => state.userState.settingsLoaded ? ((state.userState?.options && state.userState?.options[props.favoritesOptionKey]) || {}) : null);
  let dispatch = useDispatch();
  let spacesSearchOpen = useTypedSelector(store => store.uiState.isSearchOpened);

  let { loading, data: fullData, error, refetch } = useTables(props.apiEndpoint);
  const [importing, setImporting] = useState(false);
  /**
   * @type {Array}
   */
  let data = fullData;
  if (
    // if all spaces are loaded
    data?.length
    // if audit log exists
    && props.additionalData?.length
  ) {
    data = [
      ...data,
      ...props.additionalData
    ];
  }


  useHotkey('n+s', (e) => {
    // prevent the "s" being caught by the opening dialog
    e.preventDefault();

    props.onCreate();
  });

  const viewMode = props.userSettings.view || 'grid';
  const orderBy = props.userSettings.list_order_by || 'asc_last_opened';

  // Waiting for the userSettings to load will take time, so load from local storage.
  const viewModeForSkeletonOnly = props.userSettings.view || localStorage.getItem('database-list-view-mode');

  // keep the local storage up to date
  useEffect(() => {
    if (props.userSettings.view) {
      localStorage.setItem('database-list-view-mode', props.userSettings.view);
    }
  }, [props.userSettings.view]);
  
  function handleViewModeClick(e) {
    e.stopPropagation();

    const newViewMode = viewMode === 'grid' ? 'list' : 'grid';

    props.setUserSettings({
      view: newViewMode,
      list_order_by: orderBy
    });
  }

  function handleOrderBy(orderBy) {
    props.setUserSettings({
      view: viewMode,
      list_order_by: orderBy
    });
  }

  useTablesSocket({ page: props.wsPage }, (data) => {
    if (props.wsRefreshEvents.includes(data.type)) {
      refetch();
    }
  });

  if (loading || (favorites === null)) {
    return <div style={{
      textAlign: 'center'
    }} data-testid="spaces-loading">
      {!viewModeForSkeletonOnly && <LinearProgress />}

      {viewModeForSkeletonOnly === 'grid' && <DatabaseGridViewLoadingSkeleton />}
      {viewModeForSkeletonOnly === 'list' && <DatabaseListViewLoadingSkeleton />}

    </div>;
  }

  if (error) {
    return <Box sx={{ mt: 4 }} data-testid="spaces-error">
      <EmptyState
        title={`Error loading ${props.labels.itemNamePlural}`}
        icon="ERROR"
        description="Please try again later. Contact <support@blaze.today> if this issue persists."
      />
    </Box>;
  }

  /**
   * @type {SpaceObjectType[]|SiteObjectType[]}[]
   */
  let favoriteDbs = [];
  /**
   * @type {SpaceObjectType[]|SiteObjectType[]}
   */
  let otherDbs = [];
  data.forEach(db => {
    if (db.id in favorites && favorites[db.id].favorite) {
      db.favorited = true;
      favoriteDbs.push(db);
    } else {
      db.favorited = false;
      otherDbs.push(db);
    }
  });


  function getOrderedDbs() {
    /**
     * @type {(SpaceObjectType|SiteObjectType)[]}
     */
    const allDbs = [...favoriteDbs, ...otherDbs];

    if (orderBy === 'asc_favorite') {
      return allDbs;
    }

    if (orderBy === 'desc_favorite') {
      return [...otherDbs, ...favoriteDbs];
    }

    if (orderBy === 'asc_name') {
      return allDbs.sort((a,b) => a.name.localeCompare(b.name));
    }

    if (orderBy === 'desc_name') {
      return allDbs.sort((a,b) => b.name.localeCompare(a.name));
    }

    if (orderBy === 'asc_permission') {
      return allDbs.sort((a,b) => a.user_permission.localeCompare(b.user_permission));
    }

    if (orderBy === 'desc_permission') {
      return allDbs.sort((a,b) => b.user_permission.localeCompare(a.user_permission));
    }

    if (orderBy === 'asc_last_opened') {
      return allDbs.sort((a,b) => new Date(b.last_accessed).getTime() - new Date(a.last_accessed).getTime());
    }

    if (orderBy === 'desc_last_opened') {
      return allDbs.sort((a,b) => {
        if (hasNeverBeenOpened(a.last_accessed)) {
          return 1;
        }

        return new Date(a.last_accessed).getTime() - new Date(b.last_accessed).getTime();
      });
    }

    return allDbs;
  }

  const renderItem = (database) => props.renderItem(data, {
    key: database.id,
    database: database,
    refetch,
  });

  const itemsListWrapperClass =
    viewMode === 'grid'
      ? 'database-items-wrapper database-grid'
      : 'database-items-wrapper database-list';

  const noDbsCreated = !favoriteDbs.length && !otherDbs.length;

  function renderList() {
    if (noDbsCreated) {
      return props.renderEmptyComponent(props.onCreate, () => setImporting(true), () => setImporting(false));
    }

    if (viewMode === 'grid') {
      return (
        <>
          {(favoriteDbs.length)
            ? <>
              {(otherDbs.length) ?
                <T
                  variant="h4"
                  paragraph
                  sx={{
                    mt: 0,
                    mb: 4
                  }}
                  data-testid="favorites-label">
                  Favorites
                </T>
                : null
              }

              <div
                className={itemsListWrapperClass}
                data-testid="favorite-groups-grid"
              >
                {favoriteDbs.map((base) => renderItem(base))}
              </div>
            </>
            : null
          }

          {(otherDbs.length)
            ? <>
              {(favoriteDbs.length)
                ? <T
                  variant="h4"
                  paragraph
                  sx={{
                    mt: 6,
                    mb: 4
                  }}
                  data-testid="others-label">
                    Other {props.labels.itemNamePlural}
                </T>
                : null
              }

              <div
                className={itemsListWrapperClass}
                data-testid="other-groups-grid"
              >
                {otherDbs.map((base) => renderItem(base))}
              </div>
            </>
            : null
          }
        </>
      );
    }


    const orderedDbs = getOrderedDbs().filter(database => !hasNeverBeenOpened(database.last_accessed));
    const openedTodayList = orderedDbs.filter(database => moment(database.last_accessed).isSame(new Date(), 'day'));

    const today = moment().startOf('day');

    // only show "yesterday" when there's no "today" list
    const openedYesterday = openedTodayList.length
      ? null
      : orderedDbs.filter(database => {
        const lastAccessed = moment(database.last_accessed).startOf('day');
        const diffInDays = today.diff(lastAccessed, 'days');

        return diffInDays >= 1 && diffInDays < 2;
      });

    const openedPrevious7Days = orderedDbs.filter(database => {
      const lastAccessed = moment(database.last_accessed).startOf('day');
      const diffInDays = today.diff(lastAccessed, 'days');

      if (openedYesterday) {
        return diffInDays >= 2 && diffInDays < 8;
      }

      return diffInDays >= 1 && diffInDays < 7;
    });

    const openedPrevious30Days = orderedDbs.filter(database => {
      const lastAccessed = moment(database.last_accessed).startOf('day');
      const diffInDays = today.diff(lastAccessed, 'days');

      if (openedYesterday) {
        return diffInDays >= 8 && diffInDays < 38;
      }

      return diffInDays >= 7 && diffInDays < 37;
    });

    const after30Days = orderedDbs.filter(database => {
      const lastAccessed = moment(database.last_accessed).startOf('day');

      if (openedYesterday) {
        return today.diff(lastAccessed, 'days') >= 38;
      }

      return today.diff(lastAccessed, 'days') >= 37;
    });

    const neverOpened = getOrderedDbs().filter(database => hasNeverBeenOpened(database.last_accessed));

    return (
      <div
        className={itemsListWrapperClass}
        data-testid="database-items-list-wrapper"
      >
        <div className="database-items-list__header">
          <ListHeader
            className="database-list__list-item-header-name"
            value="name"
            state={orderBy}
            handleOnClick={handleOrderBy}
          >
              Name
          </ListHeader>

          <ListHeader
            className="database-list__list-item-header-last-opened"
            value="last_opened"
            state={orderBy}
            handleOnClick={handleOrderBy}
          >
              Last opened
          </ListHeader>

          <ListHeader
            className="database-list__list-item-header-permissions"
            value="permission"
            state={orderBy}
            handleOnClick={handleOrderBy}
          >
              Permission
          </ListHeader>

          <div className="database-list__list-item-header-favorites-wrapper">
            <div className="database-list__list-header">
              <ListHeader
                className="database-list__list-item-header-favorites"
                value="favorite"
                state={orderBy}
                handleOnClick={handleOrderBy}
              >
                <Tooltip title="Order by favorites">
                  <StarHalfIcon/>
                </Tooltip>
              </ListHeader>

              <Tooltip title="Change to grid view">
                <IconButton
                  data-testid="database-list-toggle-view"
                  aria-label="Button for toggling to grid mode"
                  onClick={handleViewModeClick}
                >
                  <GridOnIcon sx={{ width: 26, height: 24 }}/>
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>

        {orderBy === 'asc_last_opened'
          ? <div>

            <RenderByLastOpenedList list={openedTodayList} title="Today" renderItem={renderItem} />
            <RenderByLastOpenedList list={openedYesterday} title="Yesterday" renderItem={renderItem} />
            <RenderByLastOpenedList list={openedPrevious7Days} title="Previous 7 days" renderItem={renderItem} />
            <RenderByLastOpenedList list={openedPrevious30Days} title="Previous 30 days" renderItem={renderItem} />
            <RenderByLastOpenedList list={after30Days} title="Earlier" renderItem={renderItem} />
            <RenderByLastOpenedList list={neverOpened} title="Never opened" renderItem={renderItem} />

          </div>
          : <div> {getOrderedDbs().map((database) => renderItem(database))}
          </div>
        }
      </div>
    );
  }

  return <>
    <Helmet defer={false}>
      <title>{props.labels.productName}</title>
      <link rel="shortcut icon" href="/favicon.ico" />
    </Helmet>
    {!!props.walkthrough && <props.walkthrough />}
    <Zoom in>
      <div style={{
        position: 'fixed',
        left: 30,
        top: 18,
        zIndex: 15,
        cursor: 'pointer'
      }}>
        <Fab
          onClick={() => props.onCreate()}
          color="primary"
          data-testid="create-new-space-fab"
        >
          <Tooltip
            title={`Create a new ${props.labels.itemName}`}
            placement="bottom"
            arrow
          >
            <DatabaseAdd style={{ color: 'white', height: 56, fontSize: '240%' }}/>
          </Tooltip>
        </Fab>
      </div>
    </Zoom>
    { spacesSearchOpen && props.renderSearch(data, () => dispatch({ type: 'CLOSE_SEARCH' })) }
    <div className="database-list__wrapper-container">

      {!noDbsCreated && viewMode === 'grid' && <Tooltip title="Change to list view">
        <IconButton
          data-testid="database-list-toggle-view"
          aria-label="Button for toggling to list mode"
          onClick={handleViewModeClick}
          className="database-list__list-icon"
          sx={{
            position: 'absolute',
            top: 54,
            right: 48,
          }}
        >
          <ListIcon sx={{ width: 26, height: 26 }}/>
        </IconButton>
      </Tooltip>}

      {renderList()}
      {!importing && <LightweightVerificationReminder productName={props.labels.productName} />}

    </div>
  </>;
}

function TextSkeleton ({ sx = {}, className = '' }) {
  return <Skeleton
    className={className}
    animation="wave"
    variant="text"
    sx={sx}
  />;
}

function IconSkeleton() {
  return <Skeleton
    variant="circular"
    animation="wave"
    sx={{
      width: '1.3rem',
      height: '1.3rem'
    }}
  />;
}

function DatabaseGridViewLoadingSkeleton() {
  return (
    <div className="database-list__wrapper-container database-list_skeleton">
      <div>
        <div>
          <TextSkeleton
            sx={{
              mt: '-8px',
              mb: '26px',
              width: '160px',
              fontSize: '2.125rem'
            }}
          />
        </div>

        <Skeleton
          variant="circular"
          animation="wave"
          sx={{
            width: '1.5rem',
            height: '1.5rem',
            position: 'absolute',
            top: '62px',
            right: '62px',
          }}
        />
      </div>

      {[1, 2].map((i) =>
        <div key={i}>
          {i === 2 &&
            <div>
              <TextSkeleton
                sx={{
                  width: '260px',
                  fontSize: '2.125rem',
                  margin: '48px 0px 32px'
                }}
              />
            </div>
          }

          <div className="database-grid">
            {new Array(6 * i).fill(0).map((_, i) =>
              <div className="database-chip" key={i}>
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width={90}
                  height={90}
                />

                <TextSkeleton sx={{ width: '80px', marginTop: '12px' }}/>

              </div>
            )}
          </div>
        </div>
      )}

    </div>
  );
}

function DatabaseListViewLoadingSkeleton() {
  return (
    <div className="database-list__wrapper-container database-list_skeleton">
      <div className="database-items-list__header" style={{ paddingLeft: '8px' }}>
        <div
          className="database-list__list-item-header-name"
        >
          <TextSkeleton
            sx={{
              fontSize: '2rem',
              width: 120
            }}
          />
        </div>
        <TextSkeleton
          className="database-list__list-item-header-last-opened"
          sx={{
            fontSize: '2rem',
            width: 100
          }}
        />

        <TextSkeleton
          className="database-list__list-item-header-last-opened"
          sx={{
            fontSize: '2rem',
            width: 100
          }}
        />

        <div className="database-list__list-item-header-favorites-wrapper">
          <div className="database-list__list-header">
            <Skeleton
              variant="circular"
              animation="wave"
              sx={{
                width: '1.6rem',
                height: '1.6rem'
              }}
            />

            <Skeleton
              variant="circular"
              animation="wave"
              sx={{
                width: '1.6rem',
                height: '1.6rem'
              }}
            />
          </div>
        </div>
      </div>

      <div>
        {[3, 2, 8].map((i) => {
          return <div className="database-list__last-opened-wrapper" key={i}>
            <TextSkeleton
              className="database-list__last-opened-title"
              sx={{
                fontSize: '1.4rem',
                width: '150px',
                marginLeft: '8px'
              }} />

            {new Array(i).fill(0).map((_, index) =>
              <div className="database-list-item" key={index}>
                <div className="database-list-item__name">
                  <IconSkeleton />

                  <TextSkeleton
                    sx={{
                      fontSize: '1.5rem',
                      width: '170px',
                    }}
                  />
                </div>

                <div className="database-list-item__last_opened">
                  <TextSkeleton
                    sx={{
                      fontSize: '1.5rem',
                      width: '120px',
                    }}
                  />
                </div>

                <div className="database-list-item__last_opened">
                  <TextSkeleton
                    sx={{
                      fontSize: '1.5rem',
                      width: '80px',
                    }}
                  />
                </div>

                <div className="database-list-item__options">
                  <IconSkeleton />
                  <IconSkeleton />
                  <IconSkeleton />
                </div>

              </div>
            )}
          </div>;
        })}
      </div>
    </div>
  );
}