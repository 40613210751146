import { useEffect, useState } from 'react';
import { browser } from '../utilities';

/**
 * @param {string} extId 
 */
function getExtensionIsAlive(extId) {
  return new Promise(resolve => {
    browser().runtime.sendMessage(extId, {
      type: 'getMetadata'
    }, (response) => {
      resolve(!!response?.browser);
    });
  });
}

export function useExtensionStore({ chromeExtensionId, edgeExtensionId }) {
  // For backwards compatibility with older Chrome extension (v1.4.0), this defaults to 'chrome'
  // Later, we will change it to unknown to handle the case when neither extension is installed
  const [whichExtStoreIsInstalled, setExtInstalled] = useState(/** @type {'unknown'|'chrome'|'edge'} */('chrome'));
  /** @type {string} */
  const storeName = {
    'chrome': 'Chrome Web Store',
    'edge': 'Edge Store',
  }[whichExtStoreIsInstalled];
  /** @type {string} */
  const storeLink = {
    'chrome': `https://chromewebstore.google.com/detail/${chromeExtensionId}/reviews`,
    'edge': `https://microsoftedge.microsoft.com/addons/detail/${edgeExtensionId}`,
  }[whichExtStoreIsInstalled];
  /** @type {string} */
  const extIdToUse = {
    'chrome': chromeExtensionId,
    'edge': edgeExtensionId,
  }[whichExtStoreIsInstalled];

  useEffect(() => {
    (async () => {
      for (const [id, store] of [[chromeExtensionId, 'chrome'], [edgeExtensionId, 'edge']]) {
        const isAlive = await getExtensionIsAlive(id);
        if (isAlive) {
          setExtInstalled(store);
        }
      }
    })();
  }, [chromeExtensionId, edgeExtensionId]);

  return { storeName, storeLink, extIdToUse };
}