import { makeConfig } from '../SnippetPreview/preview_utilities';
import { Environment } from '../../snippet_processor/DataContainer';
import { createDom } from '../../snippet_processor/SnippetProcessor';
import { featureUsage } from '../Version/limitations';
import { replaceSnippetSpaceIdInDelta } from './database_utilities';
import { compressDelta } from '../../delta_proto/DeltaProto';
import { exportString } from '../../import_export/DeltaExport';
import { doTableRequest } from '../../hooks/useTables.js';


/**
 * @typedef {object} BundleTableItem
 * @property {string} name
 * @property {any[][]} data
 */

/**
 * @typedef {object} BundleSpaceItemHashed
 * @property {string} name
 * @property {string} icon
 * @property {BundleTableItem[]} tables
 */

/**
 * @typedef {object} BundleSpaceItemPublished
 * @property {string} id
 */

/**
 * @typedef {BundleSpaceItemHashed | BundleSpaceItemPublished} BundleSpaceItem
 */

/**
 * @typedef {object} BundleItem
 * @property {string} type
 * @property {BundleSpaceItem | BundleSnippetItem} data
 */

/**
 * @typedef {object} BundleSnippetItem
 * @property {string} name
 * @property {string} shortcut
 * @property {string} hidePreview
 * @property {'yes'|'no'} quickentry
 * @property {DeltaType=} delta
 * @property {DeltaType=} content
 * @property {string=} html
 * @property {string[]=} loadHostWhitelist
 * @property {string[]=} pingHostWhitelist
 * @property {object=} connected
 */

/**
 * @typedef {object} Bundle
 * @property {string} name
 * @property {BundleItem[]} items
 * @property {string=} rawCode
 */

/**
 *
 * @param {BundleSnippetItem} snippet
 * @param {Record<string, BundleSnippetItem>} snippetsMap
 * @return {Promise<string[]>}
 */
export async function getDatabaseQueries(snippet, snippetsMap) {
  const environmentConfig = makeConfig({
    config: {
      stage: 'preview',
      doNotPullInAddons: true,
      snippet: {},
      user: {},
      findSnippet: (name) => {
        if (snippetsMap[name]) {
          return {
            delta: compressDelta(snippetsMap[name].delta),
          };
        }
        return null;
      }
    }
  });

  const env = new Environment({}, environmentConfig);
  // createDom has a side effect on the passed parameter, therefore we're passing a clone
  const dom = await createDom(JSON.parse(JSON.stringify(snippet.delta)), env);
  const usage = await featureUsage(dom, env);
  if (usage.DATABASES) {
    const databaseQueries = [];
    for (const databaseUsage of Object.values(usage.DATABASES)) {
      databaseQueries.push(...databaseUsage);
    }

    return databaseQueries;
  }

  return [];
}

/**
 * @param {BundleItem[]} items
 * @param {string} applicationId
 * @return {Promise<BundleItem[]>}
 */
export async function replaceSpaceIdsInBundleItems(items, applicationId) {
  /** @type {BundleSnippetItem[]} **/
  const snippets = items.filter(item => item.type === 'snippet')
    .map(item => /** @type {BundleSnippetItem} **/ (item.data));
  await replaceSpaceIdsInBundleSnippets(snippets, applicationId);
  return items;
}


/**
 * @param {BundleSnippetItem[]} snippets
 * @param {string} applicationId
 * @return {Promise<BundleSnippetItem[]>}
 */
export async function replaceSpaceIdsInBundleSnippets(snippets, applicationId) {
  /** @type {Record<string, BundleSnippetItem>} **/
  const snippetsMap = {};
  for (const snippet of snippets) {
    snippetsMap[snippet.shortcut] = snippet;
  }

  for (const snippet of snippets) {
    snippet.delta = replaceSnippetSpaceIdInDelta(snippet.delta, 'id', applicationId);
    snippet.connected = {
      databaseQueryWhitelist: {
        [applicationId]: await getDatabaseQueries(snippet, snippetsMap),
      },
    };
    snippet.html = exportString(snippet.delta, 'html').data;
  }

  return snippets;
}



/**
 * @typedef {object} PublishedBundleSnippet
 * @property {string} name
 * @property {string} shortcut
 * @property {DeltaType} content
 */

/**
 * @typedef {object} PublishedBundle
 * @property {string} name
 * @property {string} application_id
 * @property {string=} created_by
 * @property {string=} group_id
 * @property {PublishedBundleSnippet[]=} snippets
 */

/**
 * @param {string} bundleId
 * @return {Promise<PublishedBundle | import('../../hooks/useTables.js').TableErrorResponse>}
 */
export async function fetchPublishedBundle(bundleId) {
  return doTableRequest(`database/bundles/${bundleId}/`, 'GET', null, { noAuth: true });
}
