import { useParams } from 'react-router-dom';
import { useTables } from '../../hooks/useTables.js';
import { LinearProgress } from '@mui/material';
import { EmptyState } from '../EmptyState/EmptyState.jsx';
import React, { useEffect, useState } from 'react';
import DatabaseCopy from './DatabaseCopy.jsx';
import { replaceSpaceIdsInBundleSnippets } from './bundle_utilities.js';

/**
 * @param {object} props
 * @param {string} props.bundleId
 */
function BundleLoader(props) {
  /** @type {{loading: boolean, data: import('./bundle_utilities').PublishedBundle, error: boolean}} **/
  let { loading, data, error } = useTables(`database/bundles/${props.bundleId}/`);
  const [snippetsError, setSnippetsError] = useState(false);
  const [bundleData, setBundleData] = useState(/** @type {import('./DatabasePublicSpaceEmbed').BundleInfo} **/ (null));

  useEffect(() => {
    (async() => {
      if (!loading && !!data) {
        try {
          let bundleSnippets;
          if (data.snippets?.length) {
            // transform the bundle response to bundle snippet items type
            /** @type {import('./bundle_utilities').BundleSnippetItem[]} **/
            const snippetItems = data.snippets.map(snippet => ({
              name: snippet.name,
              shortcut: snippet.shortcut,
              delta: snippet.content,
              quickentry: 'no',
              hidePreview: 'false',
            }));
            bundleSnippets = await replaceSpaceIdsInBundleSnippets(snippetItems, data.application_id);
          }
          setBundleData({
            name: data.name,
            applicationId: data.application_id,
            snippets: bundleSnippets,
          });
        } catch (error) {
          console.error(error);
          setSnippetsError(true);
        }
      }
    })();
  }, [loading, data]);

  if (snippetsError) {
    return <EmptyState
      icon="MISSING"
      title="Could not load the bundle."
      description="Error loading the bundle, please contact us at support@blaze.today."
    />;
  }

  if (error) {
    return <EmptyState
      icon="MISSING"
      title="Could not load the bundle."
      description="That bundle does not exist."
    />;
  }

  if (loading || !bundleData) {
    return <LinearProgress variant="indeterminate" />;
  }

  return <DatabaseCopy mode="bundle" bundleData={bundleData} />;
}


export function BundleCopy() {
  const { bundleId } = /** @type {{ bundleId: string }} */ (useParams());
  return <BundleLoader bundleId={bundleId} />;
}