import React, { useState, useEffect } from 'react';
import T from '@mui/material/Typography';
import { Box } from '@mui/system';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button, Slide } from '@mui/material';
import ImportSelector from './ImportSelector';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ProcessIcon from '@mui/icons-material/DocumentScanner';
import { browser } from '../../utilities';
// @ts-ignore
import cwsIcon from './cws_icon.png';
import { useExtensionStore } from '../../hooks/useExtensionStore';

const SCRAPER_EXT_ID_CHROME = 'lfgpnnanmplceafpggldoejmbbaiaico';
const SCRAPER_EXT_ID_EDGE = 'njihkobhadcmlnlikmmodkmdbobfdkhb';

export default function DBImporter(_props) {
  const [showRating, setShowRating] = useState(false);
  const [data, setData] = useState(null);
  const { storeLink, storeName, extIdToUse } = useExtensionStore({
    chromeExtensionId: SCRAPER_EXT_ID_CHROME,
    edgeExtensionId: SCRAPER_EXT_ID_EDGE,
  });

  let rows, headers, domain;
  if (data) {
    rows = data.rows;
    domain = data.domain;
    headers = Object.keys(rows[0]);
  }


  function parseScrapedData(newData) {
    setData(newData);
    console.log('setting data', newData);


    let headers = Object.keys(newData.rows[0]);

    let cleanCell = (s) => {
      return '"' + (s || '').replaceAll('"', '""') + '"';
    };

    let csv = '';
    let firstCol = true;
    for (let header of headers) {
      csv += firstCol ? '' : ',';
      firstCol = false;
      csv += cleanCell(header);
    }
    csv += '\n';
    for (let row of newData.rows) {
      firstCol = true;
      for (let header of headers) {
        csv += firstCol ? '' : ',';
        firstCol = false;
        csv += cleanCell(row[header]);
      }
      csv += '\n';
    }



    setTimeout(() => {
      const csvData = new Blob([csv], { type: 'text/csv' }); 
      const csvUrl = URL.createObjectURL(csvData);
      let link = document.querySelector('#download-link');
      link.setAttribute('href', csvUrl);
    }, 100);
  }


  useEffect(() => {
    if (extIdToUse) {
      browser().runtime.sendMessage(extIdToUse, {
        type: 'get_scraped_data'
      }, (response) => {
        if (response?.data) {
          parseScrapedData(response.data);
        }
      });
    }
  }, [extIdToUse]);


  useEffect(() => {
    if (localStorage.getItem('has_rated_or_dismissed')) {
      return;
    }

    let firstImport = localStorage.getItem('first_fws_import');
    if (!firstImport) {
      localStorage.setItem('first_fws_import', '' + Date.now());
      return;
    }


    if (firstImport) {
      // they've had it for at least 2 days
      if (+firstImport < Date.now() + 2 * 24 * 60 * 60 * 1000) {
        setShowRating(true);
      }
    }
  }, []);
  

  return <>
    {showRating && storeName && <WeaverFeedback onClose={() => {
      setShowRating(false);
    }} storeLink={storeLink} storeName={storeName} />}
    <Box sx={{
      p: 4,
      overflowY: 'auto'
    }}>
      <input
        type="hidden"
        id="data-el"
        ref={(el) => {
          if (el) {
            // Retained for backwards/cross-extension compatibility
            // this event will be triggered by the extension
            el.addEventListener('blaze-update-data', (e) => {
              // @ts-ignore
              let newData = JSON.parse(e.target.value);
              parseScrapedData(newData);
            });
          }
        }}
      />

      <T variant="h4" paragraph sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ProcessIcon sx={{
        fontSize: '64px',
        color: 'rgb(25, 118, 210)',
        opacity: .7,
        mr: 4
      }} /> Preview import</T>

      {data ? <>
        <div style={{ textAlign: 'center' }}>
          <ImportSelector rows={rows} domain={domain} />

          <TableContainer component={Paper} sx={{
            maxHeight: '500px',
            marginTop: '40px',
            marginBottom: '40px'
          }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>{headers.map(header => <TableCell key={header}>{header}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => <TableRow key={i}>
                  <TableCell>{i + 1}.</TableCell>
                  {headers.map(header => <TableCell key={header}>{row[header]}</TableCell>)}
                </TableRow>)}
              </TableBody>
            </Table>
          </TableContainer>

          <div style={{ marginTop: 32 }}>

            <ImportSelector rows={rows} domain={domain} />
            { /* eslint-disable-next-line */ }
            <div style={{ marginTop: 16 }}>or <a id="download-link" download="blaze_scaper.csv">Download as CSV</a></div>
          </div>



          <T variant="subtitle2" color="textSecondary" sx={{ mt: 6, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <InfoOutlinedIcon sx={{
              mr: 2
            }} />
            Data on this page is local to your computer and not transmitted until you import it into Data Blaze.
          </T>

        </div>



      </> : null}
    

    </Box>
  </>;
}



/**
 * 
 * @param {{ onClose: () => void, storeName: string, storeLink: string }} props 
 * @returns 
 */
function WeaverFeedback(props) {

  function close() {
    localStorage.setItem('has_rated_or_dismissed', 'true');
    props.onClose();
  }

  function openChromeStore() {
    window.open(props.storeLink, '_blank');
    close();
  };
  
  return <Slide direction="up" in>
    <Paper
      elevation={6}
      style={{
        position: 'fixed',
        right: 10,
        bottom: 10,
        width: 400,
        zIndex: 300,
        padding: '18px 24px 12px 24px'
      }}
    >
      <img src={cwsIcon} alt="CWS" width={80} style={{ float: 'left', marginRight: 20 }} />
      <T paragraph>Has Free Web Scaper helped you? Please rate us in the {props.storeName}.</T>
      <p style={{ textAlign: 'right', marginTop: 20 }}>
        <Button onClick={close} style={{ marginRight: 8 }}>Close</Button>&nbsp;&nbsp;
        <Button onClick={() => {
          openChromeStore();
        }} variant="contained" color="primary">Rate in {props.storeName}</Button>
      </p>
    </Paper>
  </Slide>;
}