import React, { useEffect, useState } from 'react';
import { Button, Chip, LinearProgress } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import T from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import AsyncButton from '../AsyncButton/AsyncButton';
import Dialog from '@mui/material/Dialog';
import { toast } from '../../message';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTypedSelectorDeepEquals } from '../../hooks';
import { doSafeTableRequest, doTableRequest } from '../../hooks/useTables';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { decompressDelta } from '../../delta_proto/DeltaProto';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Alert from '@mui/material/Alert';
import { DATA_APP_DOMAIN } from '../../flags';
import AppLink from '../Utilities/AppLink';


/**
 * @typedef {object} PublishedBundle
 * @property {string} name
 * @property {any[]=} snippets
 * @property {string=} group_id
 */

/**
 * @typedef {object} PublishedBundleGroup
 * @property {string} id
 * @property {string=} name
 * @property {string=} icon
 */


/**
 * @param {object} props
 * @param {string} props.groupId
 * @param {Object<string, any>} [props.styles]
 * @param {boolean=} props.small
 */
function OpenFolderInNew(props) {
  return <IconButton
    {...props.styles}
    size={props.small ? 'small' : undefined}
    component={AppLink}
    to={`/folder/${props.groupId}`}
    appType="TEXT"
    target="_blank"
  >
    <Tooltip title="View folder">
      <OpenInNewIcon fontSize={props.small ? 'small' : undefined} />
    </Tooltip>
  </IconButton>;
}

/**
 @param {object} props
 @param {object} props.database
 @param {function} props.onClose
 @param {boolean} props.isMvp
 */
export default function DatabasePublishDialog(props) {
  const [loading, setLoading] = useState(true);
  const [bundle, setBundle] = useState(/** @type PublishedBundle */null);
  const [selectedGroup, setSelectedGroup] = useState('none');
  const linkedGroups = useTypedSelectorDeepEquals((store) => {
    const userGroups = store.userState.groups;
    const groups = store.dataState.groups;
    const linkedGroups = [];
    const filteredGroups = {};
    for (const [id, group] of Object.entries(groups)) {
      filteredGroups[id] = {
        id: group.id,
        name: group.name,
        icon: group.options?.icon,
      };
    }

    if (userGroups) {
      const enabledGroups = Object.keys(userGroups).map(id => ({
        id,
        userInfo: userGroups[id]
      })).filter(x => !x.userInfo.disabled && groups[x.id]);

      for (const g of enabledGroups) {
        if (groups[g.id] && groups[g.id].connected && groups[g.id].connected.database_queries) {
          for (const spaceId of Object.keys(groups[g.id].connected.database_queries)) {
            if (spaceId === props.database.id) {
              linkedGroups.push({
                ...filteredGroups[g.id],
                snippets: groups[g.id].snippets,
              });
            }
          }
        }
      }
    }

    return linkedGroups;
  });

  let bundleUrl;
  if (bundle) {
    if (bundle.group_id) {
      bundleUrl = `${DATA_APP_DOMAIN}/bundle/copy/${props.database.id}`;
    } else {
      bundleUrl = `${DATA_APP_DOMAIN}/spaces/copy?space=${bundle.application_id}`;
    }
  }

  /**
   * @param {string} groupId
   * @returns {PublishedBundle}
   */
  function createBundle(groupId) {
    if (groupId === 'none') {
      return {
        name: props.database.name,
      };
    }

    const group = linkedGroups.find(group => group.id === groupId);
    return {
      group_id: groupId,
      name: group.name,
      snippets: group.snippets.map(snippet => ({
        name: snippet.name,
        shortcut: snippet.shortcut,
        content: replaceSnippetSpaceIds(decompressDelta(snippet.content.delta.toUint8Array())),
      }))
    };
  }

  function replaceSnippetSpaceIdsInOp(op) {
    if (op.insert && typeof op.insert === 'string') {
      return {
        ...op,
        insert: op.insert.replace(/space=\s*[0-9a-zA-Z]{22}\s*([;}])/g, 'space=id$1'),
      };
    }

    return op;
  }

  function replaceSnippetSpaceIds(delta) {
    delta.ops = delta.ops.map(op => replaceSnippetSpaceIdsInOp(op));
    return delta;
  }

  async function publish() {
    const bundle = createBundle(selectedGroup);
    const response = await doSafeTableRequest(
      `database/bundles/${props.database.id}/`,
      'POST',
      bundle,
    );

    if (response?.error) {
      toast(response.detail, {
        duration: 6000,
        intent: 'danger'
      });
    } else {
      setBundle(response);
    }
  }

  async function rePublish() {
    const bundle = createBundle(selectedGroup);
    const response = await doSafeTableRequest(
      `database/bundles/${props.database.id}/`,
      'PATCH',
      bundle,
    );

    if (response?.error) {
      toast(response.detail, {
        duration: 6000,
        intent: 'danger'
      });
    } else {
      setBundle(response);
    }
  }

  async function unPublish() {
    try {
      const deleteResponse = await doSafeTableRequest(
        `database/bundles/${props.database.id}/`,
        'DELETE',
        null,
        { toastMessage: 'An error occurred unpublishing the bundle.' }
      );
      if (deleteResponse?.error) {
        toast(`Error unpublishing bundle: ${deleteResponse.detail}`, {
          duration: 6000,
          intent: 'danger'
        });
      } else {
        setBundle(null);
      }
    } catch {
      // nothing to do, toast message should be displayed
    }
  }

  const groupLabel = (group) => {
    let label = group.name;
    if (group.icon) {
      label = `${group.icon} ${label}`;
    }

    return <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', marginLeft: 6, marginRight: 6, width: '100%', minHeight: 40 }}>
      <div style={{ flexGrow: 1 }}>
        {label}
      </div>
      {group.id !== 'none' && <OpenFolderInNew groupId={group.id} styles={{ padding: 0, flexShrink: 0 }}/>}
    </div>;
    // return <> </>;
  };

  function renderEmbedCode(label, code, isLink) {
    return <>
      <T variant="subtitle2" style={{ marginTop: 4 }}>{label}</T>
      <div style={{
        display: 'flex',
      }}>
        <pre style={{
          backgroundColor: '#000',
          color: '#fff',
          borderRadius: 8,
          padding: 8,
          flexGrow: 1,
          margin: 0,
          position: 'relative',
          paddingRight: 40,
        }}>
          {isLink ? <a style={{ color: 'white' }} href={code} target="_blank" rel="noreferrer">{code}</a> : code}
          <IconButton
            size="small"
            style={{
              position: 'absolute',
              top: 4,
              right: 4,
              color: 'white'
            }}
            onClick={() => {
              navigator.clipboard.writeText(code);
              toast('Copied!', {
                intent: 'success',
                duration: 700,
              });
            }}
          >
            <ContentCopyOutlinedIcon fontSize="small" />
          </IconButton>
        </pre>
      </div>
    </>;
  }

  useEffect(() => {
    (async() => {
      try {
        const bundle = await doTableRequest(`database/bundles/${props.database.id}/`, 'GET', null, { noAuth: true });
        if (bundle?.error) {
          setBundle(null);
          setSelectedGroup('none');
        } else {
          setBundle(bundle);
          if (bundle.group_id) {
            setSelectedGroup(bundle.group_id);
          } else {
            setSelectedGroup('none');
          }
        }
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return <Dialog
    open
    maxWidth="lg"
    onClose={() => props.onClose()}
  >
    <DialogTitle
      sx={{
        backgroundColor: 'grey.100',
        pr: 3,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <span>Publish a bundle of <b>{props.database.name}</b></span>
      <div style={{ flexGrow: 1 }}></div>
      <Tooltip
        title="Allowed for selected users only"
      >
        <Chip
          size="small"
          variant="outlined"
          label="Select users only"
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '100%',
            marginLeft: 6,
            fontWeight: 'normal',
          }}
        />
      </Tooltip>
    </DialogTitle>
    <DialogContent
      sx={{
        minWidth: {
          xs: 'min(calc(100% - 40px), 500px)',
          sm: '500px'
        },
        marginTop: 1,
      }}>
      <Alert severity="info" style={{
        marginBottom: 6,
      }}>
        <T>Publish a snapshot of this space and share it with anyone.</T>
      </Alert>
      { loading ? <LinearProgress variant="indeterminate" /> :
        <>
          {!props.isMvp && <>
            <T variant="subtitle1">Select a snippets folder to include in the bundle:</T>
            <Select
              variant="outlined"
              fullWidth
              aria-label="select folder"
              size="small"
              sx={{
                '&:before': {
                  content: 'none'
                },
                '& .MuiSelect-select': {
                  padding: '6px',
                },
                '& .MuiOutlinedInput-input .MuiIconButton-root': {
                  display: 'none',
                },
              }}
              value={selectedGroup}
              onChange={e => setSelectedGroup(e.target.value)}
            >
              <MenuItem value="none">{groupLabel({ id: 'none', name: '<No Folder>' })}</MenuItem>
              {linkedGroups.map(group => <MenuItem key={group.id} value={group.id}>{groupLabel(group)}</MenuItem>)}
            </Select>
          </>}
          { !!bundle && <>
            <T variant="subtitle1" style={{ marginTop: 12 }}>Embed code:</T>
            { renderEmbedCode('Blaze Community Forums', `[bundle id="${props.database.id}"]\n[/bundle]`) }
            { !props.isMvp && renderEmbedCode('Docs', `{{% bundle %}}\n@id=${props.database.id}\n{{% /bundle %}}`) }
            { renderEmbedCode('URL', bundleUrl, true) }
          </>}
        </>
      }
    </DialogContent>
    <DialogActions style={{ marginRight: 10, marginBottom: 15, justifyContent: 'flex-start', paddingLeft: 18 }}>
      { !!bundle && <AsyncButton
        onClick={async (done) => {
          await unPublish();
          done();
        }}
        color="error"
        variant="contained"
      >Unpublish</AsyncButton>}
      <div style={{ flexGrow: 1 }}></div>
      <Button
        onClick={() => props.onClose()}
        variant="outlined"
        style={{ marginRight: 12 }}
      >Cancel</Button>
      { !bundle && <AsyncButton
        onClick={async (done) => {
          await publish();
          done();
        }}
        color="primary"
        variant="contained"
      >Publish</AsyncButton> }
      { !!bundle && <AsyncButton
        onClick={async (done) => {
          await rePublish();
          done();
        }}
        color="primary"
        variant="contained"
      >Republish</AsyncButton> }
    </DialogActions>
  </Dialog>;
}