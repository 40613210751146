import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from '@store';
import { setupErrorLogging } from '../../utilities';
import { isDev } from '../../flags';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import MUITheme from '../Theme/MUITheme';
import ReloadDialog from '../ReloadDialog/ReloadDialog';
import CssBaseline from '@mui/material/CssBaseline';
import { logError } from '../../logging/logging';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';


if (!isDev() && import.meta.env.NODE_ENV !== 'test') {
  setupErrorLogging();
}



let consoleErrorsLogged = 0;

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
export default function BlazeApp(props) {

  useEffect(() => {
    let origFn = console.error;
    console.error = (...args) => {
      consoleErrorsLogged++;
      if (consoleErrorsLogged < 3) {
        logError(new Error(args.join('\n\n')), {});
      }
      origFn(...args);
    };

    return () => {
      console.error = origFn;
    };
  }, []);

  return (
    <Provider store={store}>
      <CssBaseline />
      <BrowserRouter>
        <MUITheme>
          <ErrorBoundary>
            <HelmetProvider>
              {props.children}
            </HelmetProvider>
          </ErrorBoundary>
          <ErrorBoundary>
            <ReloadDialog property="MIN_WEB_VERSION" />
          </ErrorBoundary>
        </MUITheme>
      </BrowserRouter>
    </Provider>
  );
}
