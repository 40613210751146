import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import React, { useRef, useState } from 'react';
import SpacesSearchResults from './SpacesSearchResults';


/**
 * @param {object} props
 * @param {function} props.onClose
 * @param {(SpaceObjectType|SiteObjectType)[]} props.spaces
 * @param {'spaces'|'sites'} props.itemTypePlural
 * @param {React.ComponentType=} props.defaultIcon
 * @param {(id: string) => void} props.selectFn
 */
function SpacesSearchBase(props) {
  let [search, setSearch] = useState('');
  let [searchResults, setSearchResults] = useState(/** @type any[] */ (null));
  let [highlightedIndex, setHighlightedIndex] = useState(0);
  let searchTimerId = useRef(/** @type {number} */ (null));
  let listRef = useRef();
  const { spaces } = props;

  function scrollList(listRef, newIndex) {
    if (!listRef.current) {
      return;
    }

    let [lower, upper] = listRef.current.getVisibleRange();
    if (newIndex > upper || newIndex < lower) {
      listRef.current.scrollAround(newIndex);
    }
  }

  let select = (spaceId) => {
    props.selectFn(spaceId);
    props.onClose();
  };

  function handleArrows(e) {
    if (!searchResults || !searchResults.length) {
      return;
    }
    if (e.key === 'ArrowDown') {
      let newIndexNext = Math.min(highlightedIndex + 1, searchResults.length - 1);
      scrollList(listRef, newIndexNext);
      setHighlightedIndex(newIndexNext);

      e.preventDefault();
      e.stopPropagation();
    }
    if (e.key === 'ArrowUp') {
      let newIndexPrev = Math.max(0, highlightedIndex - 1);
      scrollList(listRef, newIndexPrev);
      setHighlightedIndex(newIndexPrev);

      e.preventDefault();
      e.stopPropagation();
    }
    if (e.key === 'Enter') {
      const space = searchResults[highlightedIndex];
      select(space.id);

      e.preventDefault();
      e.stopPropagation();
    }
  }

  function searchSpaces(searchText) {
    const searchUpper = searchText.toUpperCase();
    return spaces.filter(space => space.name.toUpperCase().includes(searchUpper));
  }

  function onChangeHandler(e) {
    let newSearchText = e.target.value;
    setSearch(newSearchText);
    setHighlightedIndex(0);
    if (searchTimerId.current) {
      clearTimeout(searchTimerId.current);
      searchTimerId.current = null;
    }
    searchTimerId.current = window.setTimeout(() => {
      setSearchResults(searchSpaces(newSearchText));
    }, 80);
  }

  return <Dialog
    open
    onClose={() => props.onClose()}
    PaperProps={{
      sx: {
        width: {
          xs: 320,
          sm: 400,
          md: 640
        },
        position: 'absolute',
        height: search ? '480px' : '62px',
        top: 'max(0px, calc(50vh - 240px))',
        transition: 'height 0.1s ease-in, margin-bottom 0.1s ease-in'
      }
    }}
    data-testid="spaces-search-dialog"
  >
    <Box
      sx={{
        position: 'relative',
        borderRadius: 1,
        backgroundColor: alpha('#ffffff', 0.15),
        '&:hover': {
          backgroundColor: alpha('#ffffff', 0.25),
        },
        marginLeft: 0,
        marginTop: .8,
        marginBottom: .6,
        width: '100%',
        '& root': {
          color: 'inherit',
        },
        '& .MuiInputBase-root': {
          pt: 1,
          pr: 1,
          pb: 1,
          pl: 7
        }
      }}>
      <Box
        sx={{
          width: '45px',
          height: '100%',
          position: 'absolute',
          pointerEvents: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SearchIcon />
      </Box>
      <InputBase
        autoFocus
        placeholder={`Search for ${props.itemTypePlural}`}

        inputProps={{
          'aria-label': 'Search',
          'spellCheck': false,
          style: {
            width: '100%'
          }
        }}
        style={{
          width: '100%'
        }}
        value={search}
        onChange={onChangeHandler}
        onKeyDown={handleArrows}
      />
    </Box>

    {search && <div style={{
      paddingRight: 6,
      height: 428,
      overflowY: 'auto'
    }}>
      <SpacesSearchResults
        spaces={searchResults || []}
        highlightedIndex={highlightedIndex}
        listRef={listRef}
        handleArrows={handleArrows}
        insert={select}
        select={select}
        search={search}
        defaultIcon={props.defaultIcon}
      />
    </div>}
  </Dialog>;
}


const SpacesSearch = React.memo(SpacesSearchBase);
export default SpacesSearch;
