import React, { useState } from 'react';
import T from '@mui/material/Typography';
import { IconButton, Tooltip } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import EmojiIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import EmojiSelector from '../Messaging/LazyEmojiSelector';
import { doSafeTableRequest } from '../../hooks/useTables';
import { toast } from '../../message';
import DatabaseChipIcon from './DatabaseChipIcon';
import { Link } from 'react-router-dom';


/**
 * @typedef {object} RenderMenuProps
 * @property {Element} menuAnchor
 * @property {{top: number, left: number}} menuAnchorPosition
 * @property {function():void} onClose
 */

/**
 * @param {object} props
 * @param {SpaceObjectType|SiteObjectType} props.database
 * @param {function} props.refetch
 * @param {(menuProps: RenderMenuProps) => React.ReactNode} props.renderMenu renders the actions menu for each chip
 * @param {React.ComponentType=} props.defaultIcon the icon displayed when no one is explicitly selected by the user, default's to Space's icon
 * @param {string} props.link the link to open when clicking on the chip
 * @param {string} props.updateIconEndpoint the endpoint to call when changing chip's emoji
 * @param {string} props.itemName
 */
export default function DatabaseChip(props) {
  let [menuAnchor, setMenuAnchor] = useState(/** @type {Element} */ (null));
  let [menuAnchorPosition, setMenuAnchorPosition] = useState(/** @type {{top: number, left: number}} */ (null));
  let [showEmoji, setShowEmoji] = useState(null);

  const isShared = props.database.is_shared;
  const isOwner = props.database.user_permission === 'owner';
  const isEditor = isOwner || props.database.user_permission === 'editor';

  return <div
    className="database-chip"
    data-testid={`database-chip-${props.database.id}`}
    style={{
      position: 'relative'
    }}
  >
    {isEditor &&
        <Tooltip title="Open icon chooser">
          <IconButton
            className="database-edit-icon"
            sx={{
              position: 'absolute',
              top: -20,
              left: 0,
              zIndex: 10
            }}
            size="small"
            onClick={(e) => {
              setShowEmoji(e.currentTarget);
              e.stopPropagation();
            }}
          >
            <EmojiIcon fontSize="small" />
          </IconButton>
        </Tooltip>}


    {isEditor && showEmoji && <EmojiSelector
      target={showEmoji}
      removeLabel="Remove icon"
      showRemove={!!props.database.icon}
      onClose={() => {
        setShowEmoji(false);
      }}
      onSelect={async (emoji) => {
        try {
          await doSafeTableRequest(props.updateIconEndpoint,
            'PATCH',
            {
              icon: emoji || ''
            }, { toastMessage: 'An error occurred updating the icon' });
        } catch {
          return;
        }

        await props.refetch();

        setShowEmoji(null);

        toast(`${props.itemName} icon updated.`, {
          intent: 'success'
        });
      }}
    />}

    <Tooltip title="Open options menu">
      <IconButton
        className="database-edit-icon database-menu-icon"
        sx={{
          position: 'absolute',
          top: -20,
          right: 0,
          zIndex: 10
        }}
        size="small"
        onClick={(e) => {
          setMenuAnchor(e.currentTarget);
          e.stopPropagation();
        }}
      >
        <MoreIcon fontSize="small"/>
      </IconButton>
    </Tooltip>

    {props.renderMenu({
      menuAnchorPosition,
      menuAnchor,
      onClose: () => {
        setMenuAnchor(null);
        setMenuAnchorPosition(null);
      },
    })}
    
    <Link
      className="space-chip-link"
      to={props.link}
      style={{
        textAlign: 'center',
        textDecoration: 'none',
        color: 'inherit'
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setMenuAnchorPosition({ top: e.clientY , left: e.clientX });
      }}
    >
      <DatabaseChipIcon icon={props.database.icon} isShared={isShared} isPublished={props.database.is_published} defaultIcon={props.defaultIcon}/>
      <T variant="subtitle1" style={{
        marginTop: 12,
        wordBreak: 'break-word',
      }}>{props.database.name}</T>
    </Link>
  </div>;
}
