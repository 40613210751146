import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTables, TABLES_FRONT_END_DOMAIN } from '../../hooks/useTables';
import DatabaseFrame from './DatabaseFrame';
import { useParams } from 'react-router-dom';
import { DatabaseMissing } from './DatabaseMissing';


export default function ApiDocs() {
  const { id: databaseId } = /** @type {{ id: string }} */ (useParams());
  const [innerUrl, setInnerUrl] = useState(null);

  const { loading, data, error } = useTables(`applications/${databaseId}/`);

  useEffect(() => {
    if (!loading && !error) {
      if (!innerUrl) {
        const initialInnerUrl = `${TABLES_FRONT_END_DOMAIN}/api-docs/database/${databaseId}`;
        setInnerUrl(initialInnerUrl);
      }
    }
    // eslint-disable-next-line
  }, [loading]);

  function getTitle() {
    return (data.icon ? (data.icon + ' ') : '') + data.name + ' | Data Blaze';
  }

  if (error) {
    return <>
      <Helmet defer={false}>
        <title>Data Blaze</title>
      </Helmet>
      <DatabaseMissing />
    </>;
  }

  return <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh'
    }}>
    <Helmet defer={false}>
      <title>{!loading ? getTitle() : 'Data Blaze'}</title>
    </Helmet>
    {innerUrl && <>
      <DatabaseFrame
        url={innerUrl}
        style={{
          height: '100vh',
        }}
      >
        <></>
      </DatabaseFrame>
    </>}
  </div>;
}
