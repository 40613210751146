import { toast } from '../../message';
import { store } from '@store';
import { AUTH_URL_PREFIX, PAGE_API_PREFIX, PAGE_APP_DOMAIN, PAGE_ENGINE_PUBLISHED_DOMAIN } from '../../flags';
import React from 'react';
import SiteIconBasic from '@mui/icons-material/Web';
import { TrashedSpacesDialog } from '../Database/TrashedSpacesDialog';

export const SLUG_REGEX = /^[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

export const DEFAULT_THEME_COLOR = '#298179';

export const MENU_ITEM_ICON_STYLE = {
  opacity: 0.8,
  mr: 2,
};


/**
 *
 * @param {string} slug
 * @return {boolean}
 */
export default function validateSlug(slug) {
  slug = slug.trim();
  if (slug === '') {
    toast('The slug cannot be blank.', {
      duration: 4000,
      intent: 'danger'
    });
    return false;
  }
  if (!SLUG_REGEX.test(slug)) {
    toast('The slug can contain only alphanumeric characters or dashes, and cannot start or end with a dash.', {
      duration: 4000,
      intent: 'danger'
    });
    return false;
  }

  return true;
}

/**
 *
 * @param {string} slug
 * @return {boolean}
 */
export function validatePageSlug(slug) {
  const site = store.getState().sitesState.site;
  const slugLower = slug.toLowerCase();
  if (site.pages.some(p => p.slug.toLowerCase() === slugLower)) {
    toast(`The slug "${slugLower}" is already used in this site.`, {
      duration: 4000,
      intent: 'danger'
    });
    return false;
  }
  return validateSlug(slug);
}


/**
 * Get the base url of a site according to the published state.
 * @param {SiteObjectType} site
 * @returns {string}
 */
export function getSiteBaseUrl(site) {
  return `${site.is_published ? PAGE_ENGINE_PUBLISHED_DOMAIN : PAGE_APP_DOMAIN + AUTH_URL_PREFIX.slice(0, -1)}`;
}

export const sitesTrashDialog = () => <TrashedSpacesDialog
  groupType="site"
  trashEndpoint={`${PAGE_API_PREFIX}/trash/`}
  labels={{
    groupType: 'site',
    groupTypeCapitalized: 'Site',
    groupTypePlural: 'sites',
  }}
  defaultIcon={SiteIconBasic}
  urlHash="#trash"
/>;

/**
 * Get the id of the home page of a site, if exists.
 * @param {SiteObjectType} site
 * @returns {string}
 */
export function getSiteHomePageId(site) {
  return site.pages.find(p => p.is_active)?.id;
}


/** @type {import('../Group/ConnectedSettingsContext').ConnectedGroupButtonLabels} **/
export const connectedSiteContextValue = {
  groupType: 'site',
  itemType: 'page',
  title: 'site',
  titleCapitalized: 'Site',
  titlePluralCapitalized: 'Sites',
  itemTypePlural: 'pages',
  itemTypePluralCapitalized: 'Pages',
  groupTypeCapitalized: 'Site',
  databasePermissionPrefix: 's:',
  databasePermissionGroupType: 'site',
};