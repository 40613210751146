import React, { useState } from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import T from '@mui/material/Typography';
import { usersSettingsRef } from '@store';
import { useAppsOnboardingCompleted, useTypedSelector } from '../../hooks';
// @ts-ignore
import teamImg from './../../../images/team.png';
// @ts-ignore
import fieldImg from './../../../images/db_fields.png';
// @ts-ignore
import tablesImg from './../../../images/db_tables.png';
// @ts-ignore
import snippetsImg from './../../../images/db_snippets.png';
// @ts-ignore 
import BackgroundImage from '../../../images/background_512.png';
import { storage } from '../../utilities';
import Box from '@mui/material/Box';

function DBWalkthroughBase(_props) {
  let onboardingsCompleted = useAppsOnboardingCompleted();
  let [step, setStep] = useState(0);
  let [stage, setStage] = useState('intro');
  let [closed, setClosed] = useState(false);

  let { quest } = useTypedSelector((store) => {
    return {
      quest: store.userState && store.userState.quest
    };
  });

  
  // Don't show the snippet info card if we haven't used Text Blaze
  let numberOfSteps = onboardingsCompleted.TEXT ? 4 : 3;

  function close() {
    setClosed(true);
    storage.update(usersSettingsRef, {
      'quest.db_completed': true,
    }, 'HIDE_AUTOSAVE');
  };

  function handleDialogClose(_event, reason) {
    if (reason !== 'backdropClick') {
      close();
    }
  }

  function handleNext() {
    if (step === numberOfSteps - 1) {
      setStage('final');
    } else {
      setStep(step + 1);
    }
  };

  function handleBack() {
    if (stage === 'final') {
      setStage('steps');
    } else if (step === 0) {
      setStage('intro');
    } else {
      setStep(step - 1);
    }
  };


  function renderInner() {
    if (stage === 'intro' || stage === 'final') {
      let header, body, actions;
      if (stage === 'intro') {
        header = 'Organize Everything with Data Blaze';
        body = 'Create and collaborate on tables and sheets with Data Blaze.';
        actions = <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setStage('steps');
            }}
          >
            Start a quick tour
          </Button><br />
          <Button
            style={{ marginTop: 14, opacity: 0.7 }}
            size="small"
            onClick={() => {
              close();
            }}
          >
            Skip the tour
          </Button>
        </>;
      } else {
        header = 'Enjoy Data Blaze';
        body = 'You\'re ready to start using Data Blaze. Create a table space and get started now.';
        actions = <Button
          variant="contained"
          color="primary"
          onClick={() => {
            close();
          }}
        >
          Let's go!
        </Button>;
      }

      return <DialogContent style={{
        textAlign: 'center',
        padding: '40px 20px'
      }}>
        <T variant="h6" style={{ marginBottom: 25 }}>{header}</T>
        <T paragraph style={{ marginBottom: 30 }}>{body}</T>
        {actions}
      </DialogContent>;
    } else {

      return <>
        <DialogContent style={{ padding: 0 }}>
          {renderStep()}
        </DialogContent>
        <DialogActions style={{ padding: 0 }}>
          <MobileStepper
            style={{
              width: '100%'
            }}
            variant="dots"
            steps={numberOfSteps}
            position="static"
            activeStep={step}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={step === numberOfSteps}
                endIcon={<KeyboardArrowRight />}
              >
                Next
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                startIcon={<KeyboardArrowLeft />}
              >
                Back
              </Button>
            }
          />
        </DialogActions>
      </>;
    }
  }

  function renderStep() {
    let title, body, actions;

    if (step === 0) {
      // creation
      title = 'Create tables to manage your data';
      body = 'Create a space and add as many tables as you need to it.';
      actions = <img
        src={tablesImg}
        alt="Tables"
        style={{
          position: 'relative',
          left: -60,
          top: 24
        }}
        key="table-img"
      />;
    } else if (step === 1) {
      // fields
      title = 'Customize your tables with fields';
      body = 'Use the perfect field type for your data. For example, use an Email field for email addresses or a File field for attachments.';
      actions = <img
        src={fieldImg}
        alt="Fields"
        style={{
          position: 'relative',
          top: 10,
          left: -60
        }}
        key="fields-img"
      />;
    } else if (step === 2 && onboardingsCompleted.TEXT) {
      // snippets
      title = 'Use data from tables in your snippets';
      body = 'Use table data in Text Blaze snippets. Also, save data back to a table everytime you use a snippet.';
      actions = <img
        src={snippetsImg}
        alt="Text Blaze Snippets"
        style={{
          width: 260,
          position: 'relative',
          top: 60,
          left: -30
        }}
        key="tb-img"
      />;

    } else if (step === (onboardingsCompleted.TEXT ? 3 : 2)) {
      // sharing
      title = 'Share with teammates';
      body = 'Share table spaces with your team to collaborate together.';
      actions = <img
        src={teamImg}
        alt="Team"
        style={{
          width: 350,
          position: 'relative',
          left: -30
        }}
        key="share-img"
      />;
    } 
    return <Box
      sx={{
        overflow: 'hidden',
        display: 'flex',
        height: {
          xs: '500px',
          sm: '320px'
        },
        alignItems: {
          xs: 'start',
          sm: 'center'
        },
        flexDirection: {
          xs: 'column',
          sm: 'row'
        }
      }}

    >
      <Box sx={{
        zIndex: 10,
        flex: {
          xs: 0.4,
          sm: 1.3
        },
        paddingLeft: {
          xs: undefined,
          sm: '20px'
        },
        paddingRight: {
          xs: undefined,
          sm: '40px'
        },
        padding: {
          xs: '20px',
          sm: undefined
        },
        backgroundColor: '#6d6d6d',
        color: 'white',
        height: {
          xs: undefined,
          sm: '100%'
        },
        clipPath: {
          xs: undefined,
          sm: 'polygon(0 0, 100% 0, 90% 100%, 0 100%)'
        },
        width: '100%',
      }}>
        <Box
          sx={{
            position: {
              xs: undefined,
              sm: 'absolute'
            },
            top: {
              xs: undefined,
              sm: '45%'
            },
            transform: {
              xs: undefined,
              sm: 'translateY(-50%)'
            },
            width: {
              xs: undefined,
              sm: '270px'
            }
          }}
        >
          <T variant="h6" paragraph>{title}</T>
          <T paragraph>{body}</T>
        </Box>
      </Box>
      <Box
        sx={{
          paddingLeft: {
            xs: '110px',
            sm: '30px'
          },
          flex: 1,
          maxWidth: {
            xs: '100%',
            sm: '260px'
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {actions}
      </Box>
    </Box>;
  }

  if (!quest) {
    return null;
  }

  if (quest.db_completed) {
    return null;
  }

  if (closed) {
    return null;
  }


  return (
    <Dialog
      open
      disableEscapeKeyDown
      maxWidth={(stage === 'intro' || stage === 'final') ? 'xs' : 'sm'}
      PaperProps={{
        style: (stage === 'intro' || stage === 'final') ? {
          maxWidth: 300,
          background: `url(${BackgroundImage})`,
          backgroundColor: 'white',
          backgroundPosition: '-20px 0px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '160%'
        } : undefined
      }}
      fullWidth
      onClose={handleDialogClose}
    >
      {renderInner()}
      <link rel="preload" href={teamImg} as="image" />
      <link rel="preload" href={tablesImg} as="image" />
      <link rel="preload" href={fieldImg} as="image" />
      <link rel="preload" href={snippetsImg} as="image" />
    </Dialog>
  );
}


const DBWalkthrough = React.memo(DBWalkthroughBase);
export default DBWalkthrough;
