import { ListItemIcon, ListItemText, Tooltip, CircularProgress } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import { MENU_ITEM_ICON_STYLE } from './utils';


const defaultProgressStyle = { mr: 2.75 };
const smallProgressStyle = { mr: 2 };


/**
 * @param {object} props
 * @param {object} props.text
 * @param {React.ComponentType<any>=} props.icon
 * @param {() => Promise<void>} props.onClick
 * @param {boolean=} props.disabled
 * @param {string=} props.tooltip
 * @param {string=} props.disabledTooltip
 * @param {boolean=} props.loading
 * @param {string=} props.progressTooltip
 * @param {number=} props.progress
 * @param {boolean=} props.smallIcon
 * @param {import('@mui/material').SvgIconProps['sx']=} props.iconStyle
 * @param {import('@mui/material').CircularProgressProps['sx']=} props.progressStyle
 */
export default function AsyncMenuItem(props) {
  const [loading, setLoading] = useState(false);
  const isLoading = props.loading || loading;
  function renderMenuItem() {
    const menuItem = (
      <MenuItem
        disabled={props.disabled || isLoading}
        onClick={async() => {
          try {
            setLoading(true);
            await props.onClick();
          } finally {
            setLoading(false);
          }
        }}
      >
        <ListItemIcon>
          {isLoading ?
            <Tooltip
              title={isLoading && !!props.progressTooltip && props.progress > 0 ? `${props.progressTooltip}, ${props.progress}%.` : undefined}
              placement="bottom-start"
              arrow open={isLoading && props.progress > 0}
            >
              <CircularProgress
                color={props.progress > 0 ? 'primary' : 'inherit'}
                variant={props.progress > 0 ? 'determinate' : undefined}
                value={props.progress}
                thickness={props.progress > 0 ? 6 : undefined}
                size={18}
                sx={Object.assign({ verticalAlign: 'middle' }, props.progressStyle || (props.smallIcon ? smallProgressStyle : defaultProgressStyle))}
              />
            </Tooltip> :
            <props.icon sx={props.iconStyle || MENU_ITEM_ICON_STYLE} fontSize={props.smallIcon ? 'small' : undefined}/>
          }
        </ListItemIcon>
        <ListItemText primary={props.text} />
      </MenuItem>
    );
    if (props.disabled && props.disabledTooltip) {
      return <span>{menuItem}</span>;
    }

    return menuItem;
  }

  return props.tooltip ? <Tooltip
    title={props.disabled && props.disabledTooltip ? props.disabledTooltip : props.tooltip}
    placement="right-end"
    arrow
  >
    {renderMenuItem()}
  </Tooltip> : renderMenuItem();
}