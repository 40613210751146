import React, { useEffect, useRef, useState } from 'react';
import { useTables, doTableRequest, doSafeTableRequest, TABLES_FRONT_END_DOMAIN } from '../../hooks/useTables';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import MenuItem from '@mui/material/MenuItem';
import { Chip, LinearProgress, ListItemIcon, ListItemText, Menu } from '@mui/material';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { showConfirm, showPrompt, toast } from '../../message';
import T from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import OptionsIcon from '@mui/icons-material/MoreHoriz';
import MenuIcon from '@mui/icons-material/GridOn';
import Tooltip from '@mui/material/Tooltip';
import { DEFAULT_TABLE_NAME, showSnapshotsDialog } from './database_utilities';
import DatabaseShareDialog from './DatabaseShareDialog';
import { Helmet } from 'react-helmet-async';
import { useTypedSelector, useTypedSelectorDeepEquals, useTypedSelectorShallowEquals, } from '../../hooks';
import { isBlaze, orgPref } from '../../flags';
import DatabaseFrame from './DatabaseFrame';
import SpaceSnippetsPopover from './SpaceSnippetsPopover';
import { DatabaseChipMenu } from './DatabaseChipMenu';
import InsertEmoticonIcon from '@mui/icons-material/AddReactionOutlined';
import EmojiSelector from '../Messaging/LazyEmojiSelector';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import Check from '@mui/icons-material/Check';
import Box from '@mui/system/Box';
import { useParams } from 'react-router-dom';
import { addBreadcrumb } from '@sentry/browser';
import { SelectFolderDialog } from './SelectFolderDialog';
import AutoCompleteList from './AutoCompleteList';
import DatabasePublishDialog from './DatabasePublishDialog';
import { DatabaseMissing } from './DatabaseMissing';
import AppLink from '../Utilities/AppLink';
import useAppNavigate from '../../hooks/useAppNavigate';
import DBWalkthrough from '../Walkthrough/DBWalkthrough';
import useIFrameOrgUpdater from '../../hooks/useIFrameOrgUpdater';
import { LeftArrow, RightArrow } from './ScrollMenuArrows';
import { resendEmailVerification } from '../VerificationReminder/verificationreminder_utilites';
import { getAuth } from 'firebase/auth';
import useTablesJob from './useTablesJob';
import AsyncMenuItem from '../PageBlaze/AsyncMenuItem';
import { store } from '@store';
import { KeyboardShortcutsDialog } from '../KeyboardShortcuts/KeyboardShortcutsDialog';
import { isTextEditor } from '../../hotkeys';
import useHotkey from '../../hotkeys_hook';
import { MENU_ITEM_ICON_STYLE } from '../PageBlaze/utils';



/**
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.id
 * @param {string} props.databaseId
 * @param {boolean} props.readOnly
 * @param {any} props.onClick
 * @param {function} props.onBeforeDelete
 * @param {function} props.onDeleteError
 * @param {boolean} props.selected
 * @param {boolean} props.dialogOpen
 * @param {function} props.refetch
 * @param {function} props.navigateTo
 * @param {boolean} props.onlyTable
 * @param {number} props.index
 * @param {number} props.count
 */
function TabItem(props) {
  const { getItemById, scrollToItem, isItemVisible, initComplete } = React.useContext(VisibilityContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuRef = React.useRef();

  const { pollJob, job } = useTablesJob(props.id);

  let selected = props.selected;

  useEffect(() => {
    if (initComplete && selected) {
      if (!isItemVisible(props.id)) {
        scrollToItem(getItemById(props.id), 'smooth', 'nearest');
      }
    }
    // eslint-disable-next-line
  }, [selected, initComplete]);

  return <div
    style={{
      position: 'relative'
    }}
    onContextMenu={(e) => {
      if (!props.readOnly && selected) {
        e.preventDefault();
        setAnchorEl(menuRef.current);
        e.stopPropagation();
      }
    }}
  >
    <div
      onClick={() => {
        props.onClick();
      }}
      style={{
        opacity: selected ? 1 : .9,
        backgroundColor: selected ? (props.dialogOpen ? '#e3e3e5' : 'white') : 'rgba(255,255,255,.1)',
        color: selected ? 'black' : undefined,
        borderRadius: 5,
        alignItems: 'center',
        padding: props.readOnly ? '6px 10px' : '6px 6px 6px 10px',
        height: 30,
        cursor: selected ? undefined : 'pointer',
        userSelect: 'none',
        marginRight: 6,
        whiteSpace: 'nowrap',
        display: 'flex'
      }}
    >
      <T variant="subtitle2" style={{
        overflowX: 'hidden',
        textOverflow: 'ellipsis'
      }}>{props.name}</T>
      {!props.readOnly && <Tooltip title="Table actions">
        <IconButton
          size="small"
          style={{
            marginLeft: 12,
            padding: 0,
            visibility: selected ? undefined : 'hidden',
            width: selected ? undefined : 1
          }}
          ref={menuRef}
          onClick={async (e) => {
            setAnchorEl(e.currentTarget);
            e.stopPropagation();
          }}
        >
          <OptionsIcon
            fontSize="small"
          />
        </IconButton>
      </Tooltip>}
      {!props.readOnly && anchorEl && <Menu
        anchorEl={anchorEl}
        open
        onClick={(evt) => {
          // stop propagation to the tab, otherwise it will be wrongly clicked when
          // the user clicks outsides trying to close the menu
          evt.stopPropagation();
        }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => {
          showPrompt({
            contents: <T>Enter the new name for the table:</T>,
            confirmButtonText: 'Save',
            default: props.name,
            placeholder: 'My table',
            onConfirm: async (name) => {
              try {
                await doSafeTableRequest(
                  `database/tables/${props.id}/`,
                  'PATCH',
                  { name },
                  {
                    toastMessage: 'An error occurred updating the table name.',
                  }
                );
              } catch {
                return;
              }
              props.refetch();
            }
          });
          setAnchorEl(null);
        }}>
          <ListItemIcon><EditIcon sx={MENU_ITEM_ICON_STYLE} fontSize="small"/></ListItemIcon>
          <ListItemText>Rename table…</ListItemText>
        </MenuItem>
        <AsyncMenuItem
          text="Duplicate table"
          icon={CopyIcon}
          smallIcon
          loading={job?.inProgress}
          progress={job?.progress}
          progressTooltip="Duplicating table"
          onClick={async() => {
            try {
              const response = await doSafeTableRequest(`database/tables/${props.id}/duplicate/async/`,
                'POST',
                {},
              );
              if (response.error) {
                toast(response.detail, {
                  duration: 6000,
                  intent: 'danger'
                });
                return;
              }

              const jobResponse = await pollJob(response.id);
              await props.refetch();
              props.navigateTo(props.databaseId, jobResponse.duplicated_table.id);
            } catch (error) {
              toast(error.message, {
                duration: 6000,
                intent: 'danger'
              });
            } finally {
              setAnchorEl(null);
            }
          }}
        />
        <Divider />
        <MenuItem
          disabled={props.onlyTable}
          onClick={() => {
            showConfirm({
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Move to trash',
              intent: 'danger',
              contents: (
                <T paragraph>Are you sure you want to move this table to the trash? It will be permanently deleted after a few days.</T>
              ),
              onConfirm: async () => {
                /** @type {boolean} */
                let deleteError;
                try {
                  props.onBeforeDelete(props.id);
                  const deleteResponse = await doSafeTableRequest(
                    `database/tables/${props.id}/`,
                    'DELETE',
                    null,
                    {
                      toastMessage: 'An error occurred deleting the table.',
                    },
                  );
                  if (deleteResponse?.error) {
                    toast(`Error deleting table: ${deleteResponse.detail}`, { duration: 6000, intent: 'danger' });
                    deleteError = true;
                  } else {
                    deleteError = false;
                  }
                } catch {
                  deleteError = true;
                }

                if (deleteError) {
                  props.onDeleteError(props.id);
                  return;
                }

                await props.refetch();

                toast('Table has been moved to the trash.', {
                  duration: 6000,
                  intent: 'success'
                });
              }
            });
            setAnchorEl(null);
          }}
        >
          <ListItemIcon><DeleteIcon sx={MENU_ITEM_ICON_STYLE} fontSize="small"/></ListItemIcon>
          <ListItemText>Delete table…</ListItemText>
        </MenuItem>
      </Menu>}
    </div>
    {props.selected && <div style={{
      marginRight: 10,
      marginLeft: 4,
      position: 'relative',
      top: 4.5,
      outline: 'solid 1.5px #00acc0',
    }}>
    </div>}
  </div>;
}



function getXTransformStyle(style) {
  if (style?.transform) {
    const axisLockY = `translate(${style.transform.split(',').shift().split('(').pop()}, 0px)`;
    return {
      ...style,
      transform: axisLockY,
    };
  }
  return style;
}


/**
 * @param {object} props
 * @param {string} props.databaseId
 * @param {string=} props.tableId
 * @param {string=} props.viewId
 * @param {string=} props.rowId
 * @param {function=} props.onClose
 * @returns
 */
export default function DatabaseIdEmbed(props) {
  const navigate = useAppNavigate();

  const deleteIds = useRef([]);
  let [modalState, setModalState] = useState(false);
  let [dbOptionsAnchor, setDBOptionsAnchor] = useState(null);
  let [showEmoji, setShowEmoji] = useState(false);
  let emojiButtonRef = useRef(null);
  let newTableButtonRef = useRef(null);
  let spaceDeleteRequested = useRef(false);

  const iframeRef = useRef(null);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [innerUrl, setInnerUrl] = useState(props.tableId ?
    getInnerUrl(props.databaseId, props.tableId, props.viewId, props.rowId) :
    null
  );
  const [localTabOrder, setLocalTabOrder] = useState(null);
  let [tableId, setTableId] = useState(props.tableId);
  const [currentTable, setCurrentTable] = useState(null);

  const tableListRef = useRef(null);
  const [tableListOpen, setTableListOpen] = useState(false);

  const [showPublish, setShowPublish] = useState(false);

  const viewParams = useRef({
    databaseId: props.databaseId,
    tableId: props.tableId,
    viewId: props.viewId,
    rowId: props.rowId,
  });
  const selectFolderDialog = useRef(null);

  const { loading, data, refetch, error } = useTables(`applications/${props.databaseId}/`);
  if (localTabOrder) {
    // override table sort so we can drag and drop tables without waiting for server
    // to refresh
    let newTables = [];
    for (let id of localTabOrder) {
      let table = data.tables.find(x => x.id === id);
      if (table) {
        newTables.push(table);
      }
    }
    for (let table of data.tables) {
      if (!localTabOrder.includes(table.id)) {
        newTables.push(table);
      }
    }
    data.tables = newTables;
  }
  const isEditor = () => ['owner', 'editor'].includes(data.user_permission);
  const isOwner = () => data.user_permission === 'owner';
  let xSharingDisabled = useTypedSelectorDeepEquals(store => {
    return orgPref(store, 'xSharingDisabled');
  });

  useIFrameOrgUpdater(iframeLoading, sendMessageToIFrame);

  useEffect(() => {
    if (!iframeRef.current) {
      return;
    }

    sendMessageToIFrame('x-sharing-disabled', xSharingDisabled);
  }, [xSharingDisabled]);

  // eslint-disable-next-line
  const [_, forceRerender] = useState(0);

  const { isBlazeUser, isMvp } = useTypedSelectorShallowEquals(store => ({
    isBlazeUser: isBlaze(store.userState),
    isMvp: !!store.userState?.mvp,
  }));

  /**
   * Navigate context to a different table/view
   * (?) How navigation is handled ?
   * - On first load: load iframe with the initial inner URL
   * - On click on table: explicitly ask iframe to navigate
   * - On inner navigation: let iframe implicitly navigate, then react by pushing an entry to history
   * - On back/forward: explicitly ask iframe to navigate
   */
  function navigateTo(databaseId, tableId, viewId, rowId) {
    if (!innerUrl) {
      // initial iframe hot loading
      setInnerUrl(getInnerUrl(databaseId, tableId, viewId, rowId));
    } else if (
      viewParams.current.databaseId !== databaseId ||
      viewParams.current.tableId !== tableId ||
      viewParams.current.viewId !== viewId ||
      viewParams.current.rowId !== rowId
    ) {
      // iframe is already loaded, do internal navigation
      navigateIframe({ databaseId, tableId, viewId, rowId });
    }
  }

  /**
   * Called when browser back/forward buttons are triggered
   */
  function onPopstate() {
    const outer = parseOuterUrl(window.location.pathname);
    if (
      outer.databaseId !== viewParams.current.databaseId ||
      outer.tableId !== viewParams.current.tableId ||
      outer.viewId !== viewParams.current.viewId ||
      outer.rowId !== viewParams.current.rowId
    ) {
      navigateIframe(outer);
    }
  }


  /**
   * Called when the iframe has performed an internal navigation, it can happen due to:
   * 1. Implicit switching between different views internally.
   * 2. Explicit navigation requested by external navigation.
   */
  function onLocalNavigation(path) {
    if (path === '/') {
      // whenever Baserow needs navigation to dashboard, Data Blaze should navigate to the spaces home
      // for example: opened space is deleted
      navigate('/spaces', 'DATA');
      return;
    }

    addBreadcrumb({
      message: `Inner URL is: ${path}`,
    });
    const inner = parseInnerUrl(path);
    const outer = parseOuterUrl(window.location.pathname);

    // if Baserow is sending us a URL without a view id, we should include the current viewId in our params,
    // but we should check first if the current table info received from the Baserow is up-to-date, otherwise,
    // the outer URL is going to be fixed and replaced when receiving it
    if (!inner.viewId && currentTable?.id === inner.tableId) {
      inner.viewId = currentTable.viewId;
    }

    if (!outer) {
      // space is initially opened without the table id being present in URL, append it
      replaceHistory(getOuterUrl(inner.databaseId, inner.tableId, inner.viewId, inner.rowId));
    } else if (
      inner.databaseId !== outer.databaseId ||
      inner.tableId !== outer.tableId ||
      inner.viewId !== outer.viewId ||
      inner.rowId !== outer.rowId
    ) {
      const newOuterUrl = getOuterUrl(inner.databaseId, inner.tableId, inner.viewId, inner.rowId);
      pushToHistory(newOuterUrl);
    }

    setTableId(inner.tableId);
    viewParams.current = inner;
  }

  function removeOneDeletedTableId(tableId) {
    deleteIds.current.splice(deleteIds.current.indexOf(tableId));
  }

  function navigateIframe(params) {
    // speedup tab switching
    setTableId(params.tableId);
    sendMessageToIFrame('navigate_to', params);
  }

  function sendMessageToIFrame(type, data) {
    iframeRef.current.contentWindow.postMessage({ type, data }, TABLES_FRONT_END_DOMAIN);
  }

  function pushToHistory(url) {
    window.history.pushState(null, null, url);
  }

  function replaceHistory(url) {
    window.history.replaceState(null, null, url);
  }

  function getInnerUrl(databaseId, tableId, viewId, rowId) {
    let innerUrl = `${TABLES_FRONT_END_DOMAIN}/database/${databaseId}/table/${tableId}`;
    if (viewId) {
      innerUrl += `/${viewId}`;
    }
    if (rowId) {
      innerUrl += `/row/${rowId}`;
    }
    return innerUrl;
  }

  function getOuterUrl(databaseId, tableId, viewId, rowId) {
    let outerUrl = `/space/${databaseId}/table/${tableId}/`;
    if (viewId) {
      outerUrl += `view/${viewId}/`;
    }
    if (rowId) {
      outerUrl += `row/${rowId}/`;
    }
    return outerUrl;
  }

  function parseInnerUrl(path) {
    let viewMatch = path.match(/^\/database\/(\w+)\/table\/(\w+)(\/(\w+))?(\/row\/(\w+))?$/);
    if (viewMatch) {
      return {
        databaseId: viewMatch[1],
        tableId: viewMatch[2],
        viewId: viewMatch[4],
        rowId: viewMatch[6],
      };
    }
  }

  function parseOuterUrl(path) {
    let viewMatch = path.match(/\/space\/(\w{22})\/table\/(\w{22})(\/view\/(\w{22}))?(\/row\/(\w{22}))?\/?/);
    if (viewMatch) {
      return {
        databaseId: viewMatch[1],
        tableId: viewMatch[2],
        viewId: viewMatch[4],
        rowId: viewMatch[6],
      };
    }
  }

  function getDefaultTableId() {
    return data.tables[0].id;
  }

  async function showDeleteToastAndWait(message) {
    toast(message, {
      duration: 6000,
      intent: 'danger'
    });
    return new Promise((r) => setTimeout(r, 2000));
  }

  useEffect(() => {
    if (!loading && !error) {
      if (data.tables.length) {
        if (!innerUrl && !props.tableId) {
          // tableId is not present in URL, navigate to the default table
          navigateTo(viewParams.current.databaseId, getDefaultTableId());
        }
      }
    }
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    /** @type {HTMLLinkElement}*/
    let link = document.querySelector('link[rel~="icon"]');
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    if (data?.icon) {
      link.href = `data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>${data.icon}</text></svg>`;
    } else {
      link.href = '/favicon.ico';
    }
  }, [data?.icon]);

  useEffect(() => {
    // listen to browser forward/back events
    window.addEventListener('popstate', onPopstate, false);
    return () => window.removeEventListener('popstate', onPopstate);
    // eslint-disable-next-line
  }, []);


  const messagesListener = async (event)=> {
    const { type, data } = event.data;
    if (type === 'table_created') {
      await refetch();
      if (data.open) {
        navigateTo(props.databaseId, data.tableId);
      }
    } else if (type === 'table_deleted') {
      if (viewParams.current.tableId === data.tableId) {
        if (deleteIds.current.includes(data.tableId)) {
          // delete initiated from current window, don't show toast
          removeOneDeletedTableId(data.tableId);
        } else {
          // push in delete ids to remove from nav history
          deleteIds.current.push(tableId);
          await showDeleteToastAndWait('Current table is deleted.');
        }

        // use fresh data to detect the default table
        const { tables } = await refetch();
        navigateTo(props.databaseId, tables[0].id);
      } else {
        await refetch();
      }
    } else if (type === 'table_updated' || type === 'tables_reordered') {
      await refetch();
    } else if (type === 'reload_page') {
      window.location.reload();
    } else if (type === 'update_modal_state') {
      setModalState(event.data.data);
    } else if (type === 'navigation') {
      onLocalNavigation(event.data.data.pathname);
    } else if (type === 'not_found') {
      setCurrentTable(null);
    } else if (type === 'current_table') {
      setCurrentTable(event.data.data);
      sendMessageToIFrame('x-sharing-disabled', xSharingDisabled);

      // WORKAROUND this is a workaround for the issue resulted from Baserow's new feature:
      // (Remember the last used view, per table, per user, Baserow issue #1273)
      // now the outer URL doesn't include the viewId, if we're navigating to a different view, then hitting
      // the browser's back button to this URL again, this is going to have no effect, as the viewId is absent
      // from the outer URL, and therefore Baserow is going to use the last visited view, which will be the
      // same view before hitting back. To avoid that, we're going to replace history, adding the viewId to the
      // URL, before navigating to any different path. This way, getting back to the same rowser's history entry,
      // we'll have the correct viewId that the user expects
      const outerUrlParams = parseOuterUrl(window.location.pathname);
      if (outerUrlParams && !outerUrlParams.viewId) {
        const fixedViewParams = {
          databaseId: outerUrlParams.databaseId,
          tableId: outerUrlParams.tableId,
          viewId: event.data.data.viewId,
          rowId: outerUrlParams.rowId,
        };
        viewParams.current = fixedViewParams;
        replaceHistory(getOuterUrl(fixedViewParams.databaseId, fixedViewParams.tableId, fixedViewParams.viewId, fixedViewParams.rowId));
      }
    }  else if (type === 'current_table_fields') {
      setCurrentTable(t => ({ ...t, fields: event.data.data }));
    } else if (type === 'application_updated') {
      if (event.data.data === viewParams.current.databaseId) {
        await refetch();
      }
    } else if (type === 'application_deleted') {
      if (event.data.data === viewParams.current.databaseId && !spaceDeleteRequested.current) {
        await showDeleteToastAndWait('Current space is deleted.');
      }
    } else if (type === 'set_client_session_id') {
      store.dispatch({
        type: 'SET_CLIENT_SESSION_ID',
        clientSessionId: event.data.data,
      });
    } else if (type === 'resend_verification_email') {
      resendEmailVerification(
        getAuth().currentUser.email,
        () => sendMessageToIFrame('resend_verification_email_done')
      );
    } else if (type === 'keyboard_event') {
      // re-emit keyboard events from Baserow to current document,
      // for keyboard shortcut purposes. ignore events from text editors.
      const targetIsTextEditor = isTextEditor(
        data.target.type,
        data.target.isInputElement,
        data.target.isTextAreaElement,
        data.target.isContentEditable
      );
      if (!targetIsTextEditor) {
        document.dispatchEvent(new KeyboardEvent(data.keyboardEventType, data.options));
      }
    }
  };

  function getTitle() {
    if (loading || error) {
      return 'Data Blaze';
    }
    return `${data.name} | Data Blaze`;
  }

  // copied from Baserow's frontend
  const isOsSpecificModifierPressed = (event) => {
    const isMac = navigator.platform.toUpperCase().includes('MAC');
    return isMac ? event.metaKey : event.ctrlKey;
  };

  const showSelectFolderDialog = (snippet, openCommand = false) => {
    selectFolderDialog.current.show(snippet, openCommand);
  };

  useHotkey('cmd+/, ctrl+/', () => {
    sendMessageToIFrame('search');
  });

  useHotkey('n+t', () => {
    newTableButtonRef.current?.click();
  });

  useHotkey('g+t', () => {
    tableListRef.current?.click();
  });

  return <div
    onClick={async (evt) => {
      if (!iframeRef.current) {
        return;
      }
      if (modalState) {
        evt.stopPropagation();
        sendMessageToIFrame('hide_dialog');
      } else {
        sendMessageToIFrame('click_outside');
      }
    }}
    tabIndex={0}
    onKeyDown={(event) => {
      // Code is copied form Baserow's frontend
      if (isOsSpecificModifierPressed(event) && event.key.toLowerCase() === 'z') {
        // input/textareas/selects/editable dom elements have their own browser
        // controlled undo/redo functionality so don't use our own if they have the
        // focus.
        if (
          !['input', 'textarea', 'select'].includes(document.activeElement.tagName.toLowerCase()) &&
          !(/** @type {HTMLElement} */(document.activeElement)).isContentEditable
        ) {
          event.shiftKey ? sendMessageToIFrame('redo') : sendMessageToIFrame('undo');
          event.stopPropagation();
        }
      }
    }}
    style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh'
    }}>
    <Helmet defer={false}>
      <title>{getTitle()}</title>
    </Helmet>

    <KeyboardShortcutsDialog />

    {!loading && !error && <DBWalkthrough />}

    <div style={{
      position: 'relative'
    }}>

      {error ? <DatabaseMissing /> : (loading ?
        (innerUrl ? '' : <LinearProgress variant="indeterminate" />)
        : (data.tables.length && (
          <AppBar
            position="static"
            color="secondary"
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              pointerEvents: modalState ? 'none' : 'inherit',
              height: 90,
              boxShadow: 'none'
            }}
          >
            <div
              style={{
                display: 'flex',
                height: 58,
                alignItems: 'center',
                paddingBottom: 6
              }}
            >

              <Tooltip title="View all spaces">
                <IconButton
                  to="/spaces"
                  component={AppLink}
                  appType="DATA"
                  color="inherit"
                  sx={{
                    mx: 1,
                    opacity: .7
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Tooltip>

              <T variant="h6">
                {(isEditor() || data.icon) && <>
                  <IconButton
                    ref={emojiButtonRef}
                    sx={{
                      mr: 1,
                      color: 'white'
                    }}
                    onClick={() => {
                      setShowEmoji(!showEmoji);
                    }}
                    disabled={!isEditor()}
                  >
                    <Tooltip title="Space icon">
                      {(data.icon && typeof data.icon === 'string') ? <div
                        translate="no"
                        style={{
                          filter: 'sepia(100%) hue-rotate(170deg) contrast(1.3)',
                          fontSize: '24px',
                          lineHeight: '24px',
                          width: 24,
                          height: 24,
                          color: 'initial',
                          verticalAlign: 'center',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>{data.icon}</div> : <InsertEmoticonIcon fontSize="small" style={{
                        opacity: .6
                      }} />}
                    </Tooltip>
                  </IconButton>
                </>}

                {showEmoji && <EmojiSelector
                  target={emojiButtonRef.current}
                  removeLabel="Remove icon"
                  showRemove={!!data.icon}
                  onClose={() => {
                    setShowEmoji(false);
                  }}
                  onSelect={async (emoji) => {
                    setShowEmoji(false);
                    data.icon = emoji;
                    forceRerender(x => x + 1);

                    try {
                      await doSafeTableRequest(
                        `applications/${data.id}/`,
                        'PATCH',
                        {
                          icon: emoji || ''
                        },
                        {
                          toastMessage: 'An error occurred updating the icon',
                        }
                      );
                    } catch {
                      return;
                    }

                    refetch();
                  }}
                />}
                {data.name}
                {isEditor() && <>
                  <Tooltip title="Space actions">
                    <IconButton
                      style={{
                        color: 'white',
                        opacity: 0.7,
                        marginLeft: 12
                      }}
                      onClick={e => setDBOptionsAnchor(e.target)}
                    >
                      <OptionsIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <DatabaseChipMenu
                    menuAnchor={dbOptionsAnchor}
                    inDatabase
                    refetch={refetch}
                    onClose={() => setDBOptionsAnchor(null)}
                    database={data}
                    findUnusedSpaceName={(currentName) => {
                      return 'Copy of ' + currentName;
                    }}
                    openSnapshots={() => {
                      showSnapshotsDialog({
                        databaseNames: [],
                        database: data,
                        onRestored: () => {
                          refetch();
                        },
                      });
                    }}
                    openTrash={() => {
                      sendMessageToIFrame('open_trash', {
                        applicationId: data.id,
                        applicationName: data.name,
                      });
                    }}
                    onBeforeDelete={ () => spaceDeleteRequested.current = true }
                    onDeleteError={ () =>  spaceDeleteRequested.current = false }
                  />
                </>}</T>


              <div style={{ flex: 1 }}></div>

              {isEditor() && <>
                {currentTable && <SpaceSnippetsPopover
                  database={data}
                  currentTable={currentTable}
                  showSelectFolderDialog={showSelectFolderDialog}
                />}
                <SelectFolderDialog ref={selectFolderDialog} />
              </>}

              {!!data && (isBlazeUser || isMvp) && isOwner() && <Button
                onClick={() => setShowPublish(true)}
                color="inherit"
                size="small"
                sx={{
                  marginRight: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 2
                  },
                  backgroundColor: data.is_published ? '#0b9cc5' : 'inherit',
                }}
                style={{
                  color: 'white',
                  opacity: 0.7,
                  marginRight: 6,
                }}
              >
                Publish
              </Button>}

              {isOwner() && <DatabaseShareDialog
                database={data}
                refetch={refetch}
                entityType="database"
                buttonProps={{ size: 'small', variant: 'text', color: 'inherit', style: {
                  opacity: 0.7,
                  whiteSpace: 'nowrap'
                } }}
              />}

              <Tooltip title="Data Blaze is in Beta. Email support@blaze.today with questions."><Chip
                label="Beta"
                variant="outlined"
                size="small"
                sx={{
                  mx: 2,
                  backgroundColor: 'rgba(255,255,255, .7)'
                }}
              /></Tooltip>


              {props.onClose &&
                <IconButton
                  style={{
                    opacity: .7,
                    color: 'white',
                    marginRight: 12
                  }}
                  size="small"
                  title="Close"
                  onClick={async () => {
                    props.onClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>}


            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'middle',
                height: 36,
                marginLeft: 12,
              }}
            >


              <DragDropContext
                onDragEnd={(result) => {
                  if (!result.destination) {
                    return;
                  }

                  let sourceIndex = result.source.index;
                  let destinationIndex = result.destination.index;

                  let ids = data.tables.map(x => x.id);
                  let id = ids[sourceIndex];
                  ids.splice(sourceIndex, 1);
                  ids.splice(destinationIndex, 0, id);

                  let table = data.tables.splice(sourceIndex, 1)[0];
                  data.tables.splice(destinationIndex, 0, table);

                  setLocalTabOrder(ids);

                  (async function () {
                    try {
                      await doSafeTableRequest(
                        `database/tables/database/${props.databaseId}/order/`,
                        'POST',
                        {
                          table_ids: ids
                        },
                        {
                          toastMessage: 'Could not reorder table.',
                        }
                      );
                    } catch {
                      return;
                    }
                    await refetch();
                  })();
                }}
              >
                <Tooltip title="Table list">
                  <IconButton
                    size="small"
                    style={{
                      opacity: .7,
                      marginBottom: 6,
                      color: 'white',
                      marginRight: 2
                    }}
                    ref={tableListRef}
                    onClick={() => {
                      setTableListOpen(!tableListOpen);
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" viewBox="0 0 512 512" style={{
                      fill: 'white'
                    }}><path d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm-6 400H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v340a6 6 0 0 1-6 6zm-42-92v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm-252 12c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36z"/></svg>
                  </IconButton>
                </Tooltip>
                {tableListOpen && <Menu
                  open
                  anchorEl={tableListRef.current}
                  onClose={() => {
                    setTableListOpen(false);
                  }}
                >
                  {data.tables.map((table) => {
                    const selected = table.id === tableId;
                    return <MenuItem
                      key={table.id}
                      selected={selected}
                      onClick={() => {
                        navigateTo(props.databaseId, table.id);
                        setTableListOpen(false);
                      }}
                    >
                      <Check style={{
                        opacity: selected ? .6 : 0,
                        marginRight: 12,
                      }}/> {table.name}
                    </MenuItem>;
                  })}
                </Menu>}
                <div style={{
                  overflow: 'hidden'
                }}>
                  <Droppable
                    droppableId="space_tab_list" direction="horizontal"
                  >
                    {(provided,) => (
                      <Box
                        sx={{
                          overflowX: 'hidden',
                          '& .react-horizontal-scrolling-menu--scroll-container ': {
                            scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': {
                              display: 'none'
                            },
                          }
                        }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <ScrollMenu
                          LeftArrow={LeftArrow}
                          RightArrow={RightArrow}
                        >
                          {data.tables.map((table, i) => <Draggable
                            key={table.id}
                            draggableId={table.id}
                            index={i}
                            /** @ts-ignore - needed for ScrollMenu */
                            itemId={table.id}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={Object.assign({
                                  overflowX: 'hidden',
                                  height: 36
                                }, getXTransformStyle(
                                  provided.draggableProps.style
                                ))}
                              >
                                <TabItem
                                  key={table.id}
                                  index={i}
                                  dialogOpen={modalState}
                                  count={data.tables.length}
                                  readOnly={!isEditor()}
                                  selected={table.id === tableId}
                                  databaseId={props.databaseId}
                                  name={table.name}
                                  onlyTable={data.tables.length === 1}
                                  onClick={() => {
                                    navigateTo(props.databaseId, table.id);
                                  }}
                                  onBeforeDelete={() => deleteIds.current.push(tableId)}
                                  onDeleteError={() => removeOneDeletedTableId(table.id)}
                                  id={table.id}
                                  refetch={refetch}
                                  navigateTo={navigateTo}
                                />
                              </div>)}
                          </Draggable>)}

                          {provided.placeholder}

                        </ScrollMenu>
                      </Box>)}
                  </Droppable>

                </div>
              </DragDropContext>

              <div style={{ marginBottom: 6, display: 'flex', alignItems: 'center', flex: 1 }}>
                {isEditor() && <Button
                  ref={newTableButtonRef}
                  size="small"
                  style={{
                    color: 'white',
                    opacity: 0.7,
                    marginLeft: 18,
                    marginRight: 36,
                    whiteSpace: 'nowrap'
                  }}
                  onClick={async (e) => {
                    e.currentTarget.blur();

                    if (data.tables.length) {
                      sendMessageToIFrame('new_table');
                    } else {
                      try {
                        await doTableRequest(
                          `database/tables/database/${props.databaseId}/`,
                          'POST',
                          {
                            name: DEFAULT_TABLE_NAME,
                          }
                        );
                      } catch {
                        return toast('An error occurred creating the table. Please try again and if this issue persists, contact <support@blaze.today>.', {
                          duration: 6000,
                          intent: 'danger'
                        });
                      }


                      refetch();
                    }
                  }}
                  startIcon={<AddIcon fontSize="small" />}
                >
                  Add a table
                </Button>}
              </div>

            </div>
          </AppBar>
        )))}
    </div>
    {!error && innerUrl && <>
      <DatabaseFrame
        url={innerUrl}
        ref={iframeRef}
        showSkeleton={!loading}
        loading={iframeLoading}
        listener={messagesListener}
        onLoaded={() => setIframeLoading(false)}
        style={{
          height: '100vh',
        }}
        hidden={!data?.tables.length}
      />
      <AutoCompleteList
        frame={iframeRef}
      />
      {showPublish && <DatabasePublishDialog
        database={data}
        onClose={() => setShowPublish(false)}
        isMvp={!isBlazeUser && isMvp}
      />}
    </>}
  </div>;
}




export const DataBaseIdEmbedRouteWrapper = () => {
  const isNotLoggedIn = useTypedSelector(store => !store.userState.uid);
  const {
    id: databaseId,
    tid: tableId,
    vid: viewId,
    rid: rowId
  } = /** @type {{ id: string, tid?: string, vid?: string, rid?: string }} */ (useParams());

  if (isNotLoggedIn) {
    return null;
  }

  return <DatabaseIdEmbed
    databaseId={databaseId}
    tableId={tableId}
    viewId={viewId}
    rowId={rowId}
  />;
};