import { pollJob as pollJobFn } from './job_polling_util';
import { store } from '@store';
import { useTypedSelectorShallowEquals } from '../../hooks';

export default function useTablesJob(objectId) {
  const job = useTypedSelectorShallowEquals(store => store.dbState.jobs[objectId]);
  async function pollJob (jobId) {
    store.dispatch({
      type: 'UPDATE_JOB',
      objectId,
      job: {
        id: jobId,
        inProgress: true,
        progress: 0,
      }
    });
    return pollJobFn(jobId, (job) => store.dispatch({
      type: 'UPDATE_JOB',
      objectId,
      job: {
        id: jobId,
        inProgress: true,
        progress: job.progress_percentage,
      }
    })).finally(() => {
      store.dispatch({
        type: 'DELETE_JOB',
        objectId,
      });
    });
  }

  return { pollJob, job };
}