import React, { useEffect, useState } from 'react';
import { DatabaseField } from '../AttributeEditors/DatabaseField';
import T from '@mui/material/Typography';
import { Button, Divider, FormControl, InputLabel, LinearProgress, MenuItem, Select } from '@mui/material';
import ImportIcon from '@mui/icons-material/PublishOutlined';
import { doSafeTableRequest, doTableRequest, useTables } from '../../hooks/useTables';
import AsyncButton from '../AsyncButton/AsyncButton';
import { toast } from '../../message';
import { useTypedSelector } from '../../hooks';
import { generateAppRoute } from '../../hooks/useAppNavigate';
import { addBreadcrumb } from '@sentry/browser';


function getCacheSignature(domain, rows) {
  return 'dbi-' + domain + '-' + Object.keys(rows[0]).sort().join(',');
}


/**
 * 
 * @param {string} domain 
 * @param {object[]} rows
 * @param {string} databaseId
 * @param {string} tableId
 */
function setCached(domain, rows, databaseId, tableId) {
  localStorage.setItem(getCacheSignature(domain, rows), JSON.stringify({ databaseId, tableId }));
}

/**
 * @param {string} domain 
 * @param {object[]} rows
 */
function clearCached(domain, rows) {
  localStorage.removeItem(getCacheSignature(domain, rows));
}

/**
 * 
 * @param {string} domain 
 * @param {object[]} rows 
 * @returns {{databaseId: string, tableId: string}}
 */
function getCached(domain, rows) {
  let data = localStorage.getItem(getCacheSignature(domain, rows));
  if (data) {
    data = JSON.parse(data);
    // @ts-ignore
    return data;
  }
}


export default function ImportSelector(props) {
  let isLoaded = useTypedSelector(store => store.userState.isLoaded);
  let notLoggedIn = useTypedSelector(store => store.userState.isLoaded && !store.userState.uid);
  let signature = getCached(props.domain, props.rows);
  let [selectedDatabase, setSelectedDatabase] = useState(signature ? signature.databaseId : '_ADD');
  let [selectedTable, setSelectedTable] = useState(/** @type {{ name?: string, id: string }} */ (null));

  if (!isLoaded) {
    // placeholder
    return <div style={{ height: 60 }}></div>;
  }

  if (notLoggedIn) {
    return <div style={{
      marginTop: 30,
      marginBottom: 30
    }}>
      <span style={{
        paddingRight: 12
      }}>Data Blaze is the fastest way to save your scrapes. It's free too!</span>
      <Button
        variant="contained"
        onClick={() => {
          window.location.hash = '#signin';
        }}
        size="large"
      >
        Get Data Blaze
      </Button>
    </div>;
  }
  /**
   * 
   * @param {string} route 
   */
  const openDataLink =  (route) => {
    window.open(
      generateAppRoute(route, 'DATA').url
    );
  };

  return  <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20
  }}>
    <T style={{
      whiteSpace: 'nowrap',
      marginRight: 24
    }}>Import into </T>
    <div style={{ width: 240 }}>
      { /* @ts-ignore - no attribute */}
      <DatabaseField
        createSelects
        value={selectedDatabase}
        onChange={(id) => {
          setSelectedDatabase(id);
          setSelectedTable(null);
        }} />
    </div>
    {selectedDatabase && selectedDatabase !== '_ADD' && <TableSelector
      databaseId={selectedDatabase}
      tableId={selectedTable ? selectedTable.id : null}
      onChange={(table) => setSelectedTable(table)}
      targetTableId={signature && signature.databaseId === selectedDatabase ? signature.tableId : undefined}
      onDatabaseNotFound={() => {
        clearCached(props.domain, props.rows);
        setSelectedDatabase('_ADD');
        setSelectedTable(null);
      }}
    />}
    <AsyncButton
      variant="contained"
      startIcon={<ImportIcon />}
      size="large"
      sx={{
        ml: 2
      }}
      disabled={!selectedDatabase || (selectedDatabase !== '_ADD' && !selectedTable)}
      onClick={async (done) => {
        let databaseId = selectedDatabase;
        let tableId = selectedTable?.id;
        let tableName = selectedTable?.name;

        if (databaseId === '_ADD') {
          try {
            let newDatabase = await doSafeTableRequest('applications/application/',
              'POST',
              {
                name: props.domain + ' Scrape',
                type: 'database'
              }, { toastMessage: 'An error occurred creating the space.' });
            databaseId = newDatabase.id;
          } catch {
            done();
            return;
          }
        }
        if (!tableId || tableId === '_ADD') {
          try {
            tableName = 'Scrape ' + (new Date()).toLocaleString();
            let headers = Object.keys(props.rows[0]);
            let res = await doSafeTableRequest(`database/tables/database/${databaseId}/`,
              'POST',
              {
                name: tableName,
                data: [headers].concat(props.rows.map(row => headers.map(h => row[h]))),
                first_row_header: true
              }, { toastMessage: 'An error occurred creating the table.' });
            tableId = res.id;
            
            toast(`Imported ${props.rows.length} rows successfully`, { intent: 'success' });
            openDataLink('/space/' + databaseId + '/table/' + tableId);

            setCached(props.domain, props.rows, databaseId, tableId);

            done();
            return;

          } catch {
            done();
            return;
          }
        }


        setCached(props.domain, props.rows, databaseId, tableId);


        let res = await doTableRequest('database/query/', 'POST', 
          props.rows.map(row => ({
            database_id: databaseId,
            query: 'INSERT INTO `' + tableName + '`',
            auto_create_missing_fields: true,
            set_values: row
          })));

        if (res.status === 'success') {
          let resultCount = res.results.length;
          let successCount = res.results.filter(x => x.status === 'success').length;
          if (successCount === resultCount) {
            toast(`Imported ${resultCount} rows successfully`, { intent: 'success' });
            openDataLink('/space/' + databaseId + '/table/' + tableId);
          } else {
            toast(`Had errors for ${resultCount - successCount}/${resultCount} rows. ${res.results.find(x => x.status === 'error').message}`, { intent: 'danger' });
          }
        } else {
          toast('Error importing: ' + res.message, { intent: 'danger' });
        }

        done();
      }}
    >
        Import into Data Blaze
    </AsyncButton>
  </div>;
}


/**
 * @param {{ databaseId: string, tableId: string, onChange: (table: { name?: string, id: string}) => void, targetTableId: string, onDatabaseNotFound: () => void, }} props 
 */
function TableSelector(props) {
  let { loading, data } = useTables(`applications/${props.databaseId}/`, {
    cache_seconds: 30 * 60,
  });

  useEffect(() => {
    addBreadcrumb({
      message: 'Data changed',
      data: {
        loading,
        dataExists: !!data,
        tableId: props.tableId,
        targetTableId: props.targetTableId,
        databaseId: props.databaseId,
      },
    });
    if (data && data.tables.length) {
      if (!props.tableId) {
        if (props.targetTableId) {
          let targetTable = data.tables.find(x => x.id === props.targetTableId);
          if (targetTable) {
            props.onChange(targetTable);
            return;
          }
        }
        props.onChange(data.tables[0]);
      }
    } else if (!data && !loading) {
      console.warn(`Database ${props.databaseId} not found`);
      props.onDatabaseNotFound();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  if (loading || !data) {
    return <LinearProgress variant="indeterminate" sx={{ mt: 2 }} />;
  }

  return <FormControl variant="outlined" sx={{ ml: 2, minWidth: 180, position: 'relative', top: '4px' }}>
    <InputLabel>Table</InputLabel>
    <Select value={props.tableId || data.tables?.[0]?.id} label="Table">
      {data.tables.map(table => <MenuItem key={table.id} onClick={() => props.onChange(table)} value={table.id}>{table.name}</MenuItem>)}
      <Divider />
      <MenuItem value="_ADD" onClick={() => props.onChange({
        id: '_ADD'
      })}>Create new table</MenuItem>
    </Select>
  </FormControl>;
}