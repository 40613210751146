import React, { useState } from 'react';
import useAppNavigate from '../../hooks/useAppNavigate';
import { useTypedSelectorShallowEquals } from '../../hooks';
import { isBlaze } from '../../flags';
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { showTrashConfirmation, toast } from '../../message';
import { doSafeTableRequest } from '../../hooks/useTables';
import { storage } from '../../utilities';
import { usersSettingsRef } from '@store';
import GroupAddIcon from '@mui/icons-material/GroupAddOutlined';
import PublicIcon from '@mui/icons-material/Public';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import HistoryIcon from '@mui/icons-material/HistoryOutlined';
import KeyOutlined from '@mui/icons-material/KeyOutlined';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooksOutlined';
import RestoreFromTrashOutlined from '@mui/icons-material/RestoreFromTrashOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DocsIcon from '@mui/icons-material/BookOutlined';
import ForumIcon from '@mui/icons-material/ForumOutlined';
import DatabaseShareDialog from './DatabaseShareDialog';
import DatabasePublishDialog from './DatabasePublishDialog';
import DatabaseAPIKeysDialog from './DatabaseAPIKeysDialog';
import DatabaseListCommonMenu from './DatabaseListCommonMenu';
import AsyncMenuItem from '../PageBlaze/AsyncMenuItem';
import useTablesJob from './useTablesJob';
import { MENU_ITEM_ICON_STYLE } from '../PageBlaze/utils';



/**
 *
 * @param {object} props
 * @param {Element=} props.menuAnchor
 * @param {{top: number, left: number}=} props.menuAnchorPosition
 * @param {() => void} props.onClose
 * @param {SpaceObjectType} props.database
 * @param {() => Promise<any>} props.refetch
 * @param {boolean=} props.inDatabase
 * @param {(name: string) => string} props.findUnusedSpaceName
 * @param {(space: SpaceObjectType) => void} props.openSnapshots
 * @param {function=} props.openTrash
 * @param {function=} props.onBeforeDelete
 * @param {function=} props.onDeleteError
 * @returns
 */
export function DatabaseChipMenu(props) {
  const navigate = useAppNavigate();
  let [showShareFor, setShowShareFor] = useState(/** @type {SpaceObjectType} */null);
  let [showPublish, setShowPublish] = useState(false);
  let [showAPIKeysFor, setShowAPIKeysFor] = useState(/** @type {SpaceObjectType} */null);
  const { isBlazeUser, isMvp } = useTypedSelectorShallowEquals(store => ({
    isBlazeUser: isBlaze(store.userState),
    isMvp: !!store.userState?.mvp,
  }));
  const { pollJob, job } = useTablesJob(props.database.id);

  const isOwner = props.database.user_permission === 'owner';
  const isEditor = isOwner || props.database.user_permission === 'editor';

  /**
   * @param {string} requiredProperty
   * @returns {Promise<SpaceObjectType>}
   */
  async function fetchSpaceIfNeeded(requiredProperty) {
    if (!(requiredProperty in props.database)) {
      const databaseOrError = await doSafeTableRequest(`applications/${props.database.id}/`, 'GET');
      if (databaseOrError?.error) {
        throw new Error(databaseOrError.detail);
      }
      return /** @type {SpaceObjectType} */ (databaseOrError);
    }

    return props.database;
  }

  return <>
    {(props.menuAnchor || props.menuAnchorPosition) && <Menu
      anchorEl={props.menuAnchor}
      anchorPosition={props.menuAnchorPosition}
      anchorReference={props.menuAnchor
        ? 'anchorEl'
        : props.menuAnchorPosition
          ? 'anchorPosition'
          : 'none'}
      open
      onClose={() => {
        props.onClose();
      }}
      onContextMenu={(evt) => {
        evt.preventDefault();
        props.onClose();
      }}
    >
      <DatabaseListCommonMenu
        group={props.database}
        inDatabase={props.inDatabase}
        favoritesOptionKey="databases"
        onClose={props.onClose}
        renameEndpoint={`applications/${props.database.id}/`}
        itemName="Space"
        refetch={props.refetch}
      />
      {!props.inDatabase && isOwner && <div>
        <AsyncMenuItem
          text="Share space…"
          icon={GroupAddIcon}
          onClick={async() => {
            try {
              setShowShareFor(await fetchSpaceIfNeeded('permissions'));
            } catch {
              toast('Unknown error occurred while sharing the space, please try again. If this issue persists, please contact us at support@blaze.today', {
                intent: 'danger'
              });
            }
            props.onClose();
          }}
        />
        {(isBlazeUser || isMvp) &&
          <MenuItem
            onClick={() => {
              props.onClose();
              setShowPublish(true);
            }}
          >
            <ListItemIcon><PublicIcon sx={MENU_ITEM_ICON_STYLE} /></ListItemIcon>
            <ListItemText>Publish space…</ListItemText>
          </MenuItem>
        }
      </div>}
      <AsyncMenuItem
        text="Duplicate space"
        icon={CopyIcon}
        loading={job?.inProgress}
        progress={job?.progress}
        progressTooltip="Duplicating space"
        onClick={ async() => {
          try {
            // get unused app name, we're doing that client side to avoid user/group permissions hassles
            // that otherwise will be needed to get handled on the server side
            const unusedName = props.findUnusedSpaceName(props.database.name);
            const response = await doSafeTableRequest(`applications/${props.database.id}/duplicate/async/`,
              'POST',
              { unused_name: unusedName });
            if (response.error) {
              toast(response.detail, {
                duration: 6000,
                intent: 'danger'
              });
              return;
            }

            const jobResponse = await pollJob(response.id);
            const duplicatedAppId = jobResponse.duplicated_application.id;
            await storage.update(usersSettingsRef, {
              [`options.databases.${duplicatedAppId}.favorite`]: true,
            });
            window.location.assign(`/space/${duplicatedAppId}`);
          } catch (error) {
            toast(error.message, {
              duration: 6000,
              intent: 'danger'
            });
          } finally {
            props.onClose();
          }
        }}
      />
      {isOwner &&
        <MenuItem
          onClick={() => {
            props.onClose();
            props.openSnapshots(props.database);
          }}
        >
          <ListItemIcon><HistoryIcon sx={MENU_ITEM_ICON_STYLE} /></ListItemIcon>
          <ListItemText>Snapshots…</ListItemText>
        </MenuItem>
      }
      {isOwner && [
        <Divider key="api-divider" />,
        <AsyncMenuItem
          key="api-tokens"
          text="API tokens…"
          icon={KeyOutlined}
          onClick={async() => {
            try {
              setShowAPIKeysFor(await fetchSpaceIfNeeded('tables'));
            } catch {
              toast('Unknown error occurred while reading API tokens, please try again. If this issue persists, please contact us at support@blaze.today', {
                intent: 'danger'
              });
            }
            props.onClose();
          }}
        />,
        <MenuItem
          key="api-docs"
          component="a"
          href={`/api-docs/database/${props.database.id}`}
          target="_blank"
          onClick={() => {
            props.onClose();
          }}
        >
          <ListItemIcon><LibraryBooksIcon sx={MENU_ITEM_ICON_STYLE} /></ListItemIcon>
          <ListItemText>API documentation…</ListItemText>
        </MenuItem>
      ]}
      {props.inDatabase && [
        <Divider key="docs-divider" />,
        <MenuItem
          key="docs-link"
          component="a"
          href="https://blaze.today/datablaze/docs/"
          target="_blank"
          onClick={(e) => props.onClose()}
        >
          <ListItemIcon><DocsIcon sx={MENU_ITEM_ICON_STYLE} /></ListItemIcon>
          <ListItemText>Docs…</ListItemText>
        </MenuItem>,
        <MenuItem
          key="community-link"
          component="a"
          href="https://community.blaze.today"
          target="_blank"
          onClick={(e) => props.onClose()}
        >
          <ListItemIcon><ForumIcon sx={MENU_ITEM_ICON_STYLE} /></ListItemIcon>
          <ListItemText>Community forums…</ListItemText>
        </MenuItem>
      ]}
      {props.inDatabase && isEditor && [
        <Divider key="trash-divider" />,
        <MenuItem
          key="trashed-items"
          onClick={async (e) => {
            props.onClose();
            e.currentTarget.blur();
            props.openTrash();
          }}
        >
          <ListItemIcon><RestoreFromTrashOutlined sx={MENU_ITEM_ICON_STYLE} /></ListItemIcon>
          <ListItemText>Trashed items…</ListItemText>
        </MenuItem>
      ]}
      {isOwner && [
        <Divider key="delete-divider" />,
        <MenuItem
          key="delete-space"
          onClick={() => {
            props.onClose();
            showTrashConfirmation({
              item: <><b>{props.database.name}</b> space</>,
              onDelete: async () => {
                if (props.onBeforeDelete) {
                  props.onBeforeDelete();
                }

                /** @type {boolean} */
                let deleteError;
                try {
                  const deleteResponse = await doSafeTableRequest(`applications/${props.database.id}/`,
                    'DELETE', null, { toastMessage: 'An error occurred deleting the space.' });
                  if (deleteResponse?.error) {
                    toast(`Error deleting space: ${deleteResponse.detail}`, { duration: 6000, intent: 'danger' });
                    deleteError = true;
                  } else {
                    deleteError = false;
                  }
                } catch {
                  deleteError = true;
                }

                if (deleteError) {
                  if (props.onDeleteError) {
                    props.onDeleteError();
                  }
                  return;
                }

                if (props.inDatabase) {
                  // redirect to the list
                  navigate('/spaces', 'DATA');
                } else {
                  await props.refetch();

                  toast('The space has been moved to the trash.', {
                    intent: 'success'
                  });
                }
              }
            });
          }}
        >
          <ListItemIcon><DeleteIcon sx={MENU_ITEM_ICON_STYLE} /></ListItemIcon>
          <ListItemText>Delete space…</ListItemText>
        </MenuItem>
      ]}
    </Menu>}
    {!!showShareFor && <DatabaseShareDialog
      database={showShareFor}
      refetch={/** @type{(updatedData: Partial<SpaceObjectType>) => void} */ (updatedData) => {
        // update the share dialog data
        setShowShareFor(v => Object.assign({}, v, updatedData));
        // update the spaces list
        props.refetch();
      }}
      onClose={() => setShowShareFor(null)}
      entityType="database"
    />}
    {showPublish && <DatabasePublishDialog
      database={props.database}
      onClose={() => setShowPublish(false)}
      isMvp={!isBlazeUser && isMvp}
    />}
    {!!showAPIKeysFor && <DatabaseAPIKeysDialog
      database={showAPIKeysFor}
      onClose={() => setShowAPIKeysFor(null)}
    />}
  </>;
}
