import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ReactList from 'react-list';
import { itemStyleFn } from '../SnippetList/shared';
import { EmptyState } from '../EmptyState/EmptyState';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import DatabaseChipIcon from './DatabaseChipIcon';
import { ListItemIcon } from '@mui/material';
import { highlightResults } from './search_utils';


/**
 * @param {object} props
 * @param {SpaceObjectType[]} props.spaces
 * @param {number} props.highlightedIndex
 * @param {string} props.search
 * @param {object} props.listRef
 * @param {any} props.handleArrows
 * @param {function} props.insert
 * @param {function} props.select
 * @param {React.ComponentType=} props.defaultIcon
 */
function SpacesSearchResultsBase(props) {
  let spaces = props.spaces;

  if (spaces.length === 0 && props.search) {
    return <div className="no-snippets">
      <EmptyState
        icon={ZoomOutIcon}
        title="Nothing matched your search"
        description="Try searching for something else..."
      />
    </div>;
  }

  return <div>
    <List
      dense
      className="micro-list"
      onKeyDown={props.handleArrows}
    >
      <ReactList
        pageSize={20}
        threshold={600}
        useStaticSize
        ref={props.listRef}
        itemRenderer={(itemIndex) => {
          let space = spaces[itemIndex];
          let selected = props.highlightedIndex === itemIndex;
          let style = itemStyleFn({
            selected: selected,
            depth: 0
          });

          // we set border radius below so need to delete
          // this prop.
          delete style.borderRadius;

          let styleObj = Object.assign(style, {
            borderTopRightRadius: 100,
            borderBottomRightRadius: 100,
            paddingTop: 6,
            paddingBottom: 6,
          });

          return <ListItem
            key={itemIndex}
            button
            style={styleObj}
            onClick={() => {
              props.select(space.id, itemIndex);
            }}>
            <ListItemIcon>
              <DatabaseChipIcon simple icon={space.icon} defaultIcon={props.defaultIcon}/>
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }
              }}
            >{highlightResults(space.name, props.search)}</ListItemText>
          </ListItem>;
        }}
        length={spaces.length}
        type="uniform"
      />
    </List>
  </div>;
}


const SpacesSearchResults = React.memo(SpacesSearchResultsBase);
export default SpacesSearchResults;