import React, { useState } from 'react';
import T from '@mui/material/Typography';
import { IconButton, Tooltip } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import EmojiSelector from '../Messaging/LazyEmojiSelector';
import { doSafeTableRequest } from '../../hooks/useTables';
import { toast } from '../../message';
import Avatar from '@mui/material/Avatar';
import DatabaseIcon from '@mui/icons-material/TableChart';
import PublicIcon from '@mui/icons-material/Public';
import DatabaseShareIcon from '@mui/icons-material/PeopleOutlineOutlined';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { storage } from '../../utilities';
import { usersSettingsRef } from '@store';
import Box from '@mui/material/Box';
import moment from 'moment';
import { hasNeverBeenOpened } from './database_utilities';
import { Link } from 'react-router-dom';

/**
 * @param {object} props
 * @param {React.ComponentType=} props.DefaultIcon
 * @param {string} props.dbIcon
 */
function ListItemIcon({ DefaultIcon, dbIcon }) {
  if (dbIcon) {
    // make the icon be centered and a square
    return (
      <Box sx={{ lineHeight: 1, position: 'relative', top: 1, fontSize: 20 }}>
        {dbIcon}
      </Box>
    );
  }

  return (
    <Avatar
      sx={{
        backgroundColor: 'secondary.light',
        userSelect: 'none',
        width: 21,
        height: 21,
        svg: {
          fontSize: '14px',
        },
      }}
    >
      {!!DefaultIcon ? <DefaultIcon /> : <DatabaseIcon />}
    </Avatar>
  );
}

/**
 * @param {object} props
 * @param {boolean} props.isPublished
 * @param {boolean} props.isShared
 * @returns {JSX.Element|null}
 */
function PublicOrSharedIcon({ isPublished, isShared }) {
  if (isPublished) {
    return (
      <Tooltip title="Public space">
        <Box display="flex">
          <PublicIcon />
        </Box>
      </Tooltip>
    );
  } else if (isShared) {
    return (
      <Tooltip title="Shared space">
        <Box display="flex">
          <DatabaseShareIcon />
        </Box>
      </Tooltip>
    );
  }

  return null;
}

/**
 * @typedef {object} RenderMenuProps
 * @property {{top: number, left: number}} menuAnchorPosition
 * @property {Element} menuAnchor
 * @property {function():void} onClose
 */

/**
 * @param {object} props
 * @param {SpaceObjectType|SiteObjectType} props.database
 * @param {function} props.refetch
 * @param {(menuProps: RenderMenuProps) => React.ReactNode} props.renderMenu renders the actions menu for each item
 * @param {React.ComponentType=} props.defaultIcon the icon displayed when no one is explicitly selected by the user, default's to Space's icon
 * @param {string} props.link the link to open when clicking on the item
 * @param {string} props.updateIconEndpoint the endpoint to call when changing item's emoji
 * @param {string} props.itemName
 * @param {string} props.favoritesOptionKey
 */
export default function DatabaseListItem(props) {
  let [menuAnchorPosition, setMenuAnchorPosition] = useState(/** @type {{top: number, left: number}} */ (null));
  let [menuAnchor, setMenuAnchor] = useState(/** @type {Element} */ (null));
  let [showEmoji, setShowEmoji] = useState(null);

  const isShared = props.database.is_shared;
  const userPermission = props.database.user_permission;
  const isOwner = userPermission === 'owner';
  const isEditor = isOwner || userPermission === 'editor';

  const permission =
    userPermission.charAt(0).toUpperCase() + userPermission.slice(1);

  function getLastOpened() {
    if (hasNeverBeenOpened(props.database.last_accessed)) {
      return '';
    }

    const lastAccessed = new Date(props.database.last_accessed);
    const last7Days = new Date(new Date().setDate(new Date().getDate() - 7));

    if (lastAccessed > last7Days) {
      const humanReadableDate = moment(lastAccessed).fromNow();
      return (
        humanReadableDate.charAt(0).toUpperCase() + humanReadableDate.slice(1)
      );
    }

    // "Jun 28, 2023"
    return lastAccessed.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }

  /**
   * @param {boolean} isFavorite
   */
  function toggleFavorite(isFavorite) {
    storage.update(usersSettingsRef, {
      [`options.${props.favoritesOptionKey}.${props.database.id}.favorite`]: isFavorite,
    });
  }

  return (
    <>
      {props.renderMenu({
        menuAnchorPosition,
        menuAnchor,
        onClose: () => {
          setMenuAnchor(null);
          setMenuAnchorPosition(null);
        },
      })}

      <Link
        to={props.link}
        style={{
          color: 'inherit',
          textDecoration: 'none'
        }}
        className="database-list-item"
        data-testid={`database-list-item-${props.database.id}`}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();

          setMenuAnchorPosition(menuAnchorPosition ? null : { left: e.clientX, top: e.clientY });
        }}
      >
        <div className="database-list-item__name">
          {isEditor ? (
            <Tooltip title="Open icon chooser">
              <IconButton
                component="span"
                className="database-list-item__emoji-selector-button"
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  setShowEmoji(e.currentTarget);
                }}
              >
                <ListItemIcon
                  dbIcon={props.database.icon}
                  DefaultIcon={props.defaultIcon}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <div style={{ padding: '5px', cursor: 'default' }}>
              <ListItemIcon
                dbIcon={props.database.icon}
                DefaultIcon={props.defaultIcon}
              />
            </div>
          )}
          {isEditor && showEmoji && (
            <EmojiSelector
              target={showEmoji}
              removeLabel="Remove icon"
              showRemove={!!props.database.icon}
              onClose={() => {
                setShowEmoji(false);
              }}
              onSelect={async (emoji) => {
                try {
                  await doSafeTableRequest(
                    props.updateIconEndpoint,
                    'PATCH',
                    {
                      icon: emoji || '',
                    },
                    { toastMessage: 'An error occurred updating the icon' },
                  );
                } catch {
                  return;
                }

                await props.refetch();

                setShowEmoji(null);

                toast(`${props.itemName} icon updated.`, {
                  intent: 'success',
                });
              }}
            />
          )}

          <T
            variant="subtitle1"
            sx={{
              wordBreak: 'break-word',
              fontWeight: 500,
            }}
          >
            {props.database.name}
          </T>
        </div>

        <div className="database-list-item__last_opened">{getLastOpened()}</div>

        <div className="database-list-item__permission">{permission}</div>

        <div className="database-list-item__options">
          <PublicOrSharedIcon
            isShared={isShared}
            isPublished={props.database.is_published}
          />

          {props.database.favorited ? (
            <Tooltip title="Remove from favorites">
              <IconButton
                component="span"
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  toggleFavorite(false);
                }}
                data-testid="database-list-item-remove-favorite"
              >
                <StarIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Add to favorites">
              <IconButton
                component="span"
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  toggleFavorite(true);
                }}
                data-testid="database-list-item-add-favorite"
              >
                <StarOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Open options menu">
            <IconButton
              component="span"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                setMenuAnchor(e.currentTarget);
              }}
            >
              <MoreIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </Link>
    </>
  );
}
