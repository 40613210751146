import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { store, isOrg } from '@store';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { dialogs, toast } from '../../message';
import { dialog } from '../DataDialog/DataDialog';
import AuthDialog from '../Auth/AuthDialog';
import { fontStyle } from '../SnippetEditor/fontConfig';
import { DataBaseIdEmbedRouteWrapper as DatabaseIdEmbed } from '../Database/DatabaseIdEmbed';
import { Helmet } from 'react-helmet-async';
import DatabasePublicPageEmbed from '../Database/DatabasePublicPageEmbed';
import { useIsPublicUrl } from '../../hooks/useIsPublicUrl';
import { databaseDialogs, spacesTrashDialog } from '../Database/database_utilities';
import ApiDocs from '../Database/ApiDocs';
import { deleteTokenCookie, getTokenCookie } from '../../flags';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import DatabaseCopy from '../Database/DatabaseCopy';
import DBImporter from '../DBImporter/DBImporter';
import DataInnerMain from './DataInnerMain';
import { StatusNotification } from '../StatusNotification/StatusNotification';
import { BundleCopy } from '../Database/BundleCopy';
const DatabasePublicSpaceEmbed = React.lazy(() => import('../Database/DatabasePublicSpaceEmbed'));


function DataAppInner() {
  const isPublicUrl = useIsPublicUrl();
  useEffect(() => {
    const token = getTokenCookie();
    if (!token) {
      return;
    }
    signInWithCustomToken(getAuth(), token)
      .then(deleteTokenCookie)
      .catch(err => toast('Failed to login. Please try again after sometime. ' + err.message, {
        intent: 'danger'
      }));
  }, []);
  return (
    <>
      <Helmet defer={false}>
        <title>Data Blaze</title>
      </Helmet>
      <style>{fontStyle}</style>
      <Switch>
        <Route
          exact path={[
            '/space/:id',
            '/space/:id/table/:tid',
            '/space/:id/table/:tid/row/:rid',
            '/space/:id/table/:tid/view/:vid',
            '/space/:id/table/:tid/view/:vid/row/:rid',
          ]}
          render={() => <ErrorBoundary>
            <DatabaseIdEmbed />
          </ErrorBoundary>}
        />
        <Route exact path="/spaces/copy" render={() => <ErrorBoundary>
          <DatabaseCopy mode="space" />
        </ErrorBoundary>} />
        <Route exact path="/bundle/copy" render={() => <ErrorBoundary>
          <DatabaseCopy mode="bundle" />
        </ErrorBoundary>} />
        <Route exact path="/bundle/copy/:bundleId" render={() => <ErrorBoundary>
          <BundleCopy />
        </ErrorBoundary>} />
        <Route
          exact path={[
            '/public/grid/:publicViewId',
            '/public/gallery/:publicViewId',
            // TODO BACKWARD COMPATIBILITY remove /:mode part March 2024
            '/public/form/:formId/:mode(preview)?',
          ]}
          render={() => <ErrorBoundary>
            <DatabasePublicPageEmbed />
          </ErrorBoundary>}
        />
        <Route exact path="/public/embed/space/" render={() => <ErrorBoundary>
          <DatabasePublicSpaceEmbed />
        </ErrorBoundary>} />
        <Route exact path="/datablaze/import/" render={() => <ErrorBoundary>
          <DBImporter />
        </ErrorBoundary>} />
        <Route exact path="/api-docs/database/:id" render={() => <ErrorBoundary>
          <ApiDocs />
        </ErrorBoundary>} />
        <Route component={DataInnerMain} />
      </Switch>

      {!isPublicUrl && <AuthDialog />}
      {dialogs({ store, isOrg })}
      <StatusNotification />
      {databaseDialogs()}
      {spacesTrashDialog()}
      <ErrorBoundary dialog details="in the data dialog">{dialog}</ErrorBoundary>
    </>
  );
}


export default DataAppInner;
