import React from 'react';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Grid from '@mui/material/Grid';
import './inner_main.css';
import SpacesList from '../Database/SpacesList';

export default function InnerModuleTables() {
  return <div className="container-fluid page-body-with-scroll">
    <Grid container style={{ height: '100%' }}>
      <Grid item xs={12} sm={12} md={12} style={{ height: '100%' }}>
        <ErrorBoundary>
          <SpacesList />
        </ErrorBoundary>
      </Grid>
    </Grid>     
  </div>;
}