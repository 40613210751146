import React, { useState } from 'react';
import {
  DialogActions,
  Pagination,
} from '@mui/material';
import { doSafeTableRequest, useTables } from '../../hooks/useTables';
import DatabaseChipIcon from './DatabaseChipIcon';
import T from '@mui/material/Typography';
import TrashedDialog from '../TrashedDialog/TrashedDialog';
import { useHistory, useLocation } from 'react-router-dom';
import TrashedDialogTitle from '../TrashedDialog/TrashedDialogTitle';
import TrashedDialogContent from '../TrashedDialog/TrashedDialogContent';
import TrashedList from '../TrashedDialog/TrashedList';
import TrashedEmptyState from '../TrashedDialog/TrashedEmptyState';
import TrashedInfo from '../TrashedDialog/TrashedInfo';

const PAGE_SIZE = 20;


/**
 * @typedef {object} TrashedSpacesProps
 * @property {'application'|'site'|'page'} groupType the key used in the API endpoint and JSON responses
 * @property {string} trashEndpoint
 * @property {import('../TrashedDialog/TrashedList').TrashedDialogLabels} labels
 * @property {React.ComponentType=} defaultIcon
 * @property {() => Promise<void>=} refetch
 * @property {(data: any, refetch: () => Promise<void>) => React.ReactElement=} renderDialogActions
 * @property {boolean=} hideDelete
 * @property {React.ReactNode=} emptyButton
 * @property {string=} trashPaginatedEndpoint
 */


/**
 * @param {TrashedSpacesProps & {urlHash: string}} props
 * @return {JSX.Element|null}
 */
export const TrashedSpacesDialog = function(props) {
  const location = useLocation();
  if (location.hash !== props.urlHash) {
    return null;
  }

  return <TrashedSpacesDialogBase {...props} />;
};


/**
 * @param {TrashedSpacesProps} props
 * @return {JSX.Element|null}
 */
export const TrashedSpacesDialogBase = function(props) {
  const { push: navigate } = useHistory();
  const [paginationDisabled, setPaginationDisabled] = useState(false);
  const paginated = !!props.trashPaginatedEndpoint;
  const [pageNumber, setPageNumber] = useState(1);
  const { loading, data: fetchedData, error, refetch } = useTables(
    paginated ?
      `${props.trashPaginatedEndpoint}?page=${pageNumber}&size=${PAGE_SIZE}` :
      props.trashEndpoint
  );
  let count,
    pagesCount,
    /**
     * @type {(import('../TrashedDialog/TrashedList').TrashedData & {trash_item_id: string, parent_trash_item_id: string, trash_item_type: string, user_who_trashed: { name: string, email: string } })[]}
     */
    data;
  if (paginated) {
    count = fetchedData?.count || 0;
    pagesCount = Math.ceil(count / PAGE_SIZE);
    data = fetchedData?.results;
  } else {
    data = fetchedData;
  }
  const handleClose = () => {
    navigate({ hash: '' });
  };


  return (
    <TrashedDialog
      onClose={handleClose}
    >
      <TrashedDialogTitle
        onClose={handleClose}
      >
        Trashed {props.labels.groupTypePlural}
      </TrashedDialogTitle>
      <TrashedDialogContent
        loading={loading}
      >
        {!loading && !error && !data?.length && <TrashedEmptyState
          label={props.labels.groupTypePlural}
        />}
        {!loading && !error && !!data?.length && (
          <TrashedInfo
            label={props.labels.groupTypePlural}
          />
        )}
        <TrashedList
          labels={props.labels}
          hideDelete={props.hideDelete}
          data={
            data?.map(d => ({
              ...d,
              icon: d[props.groupType]?.icon,
              trashed_by: d.user_who_trashed
            }))
          }
          onDelete={async (item) => {
            const deleteResponse = await doSafeTableRequest(`${props.trashEndpoint}${props.groupType}/${item.trash_item_id}/`,
              'DELETE', null);
            if (deleteResponse?.error) {
              throw Error(deleteResponse.detail);
            }
            setPageNumber(1);
            await refetch();
          }}
          onRestore={async (item) => {
            try {
              setPaginationDisabled(true);
              const restoreResponse = await doSafeTableRequest(`${props.trashEndpoint}restore/`,
                'PATCH', {
                  trash_item_id: item.trash_item_id,
                  parent_trash_item_id: item.parent_trash_item_id,
                  trash_item_type: item.trash_item_type,
                });
              if (restoreResponse?.error) {
                throw Error(restoreResponse.detail);
              }
              await props.refetch?.();
              setPageNumber(1);
              await refetch();
            } finally {
              setPaginationDisabled(false);
            }
          }}
          error={!!error}
          IconComponent={DatabaseChipIcon}
          IconComponentProps={{
            simple: true,
            defaultIcon: props.defaultIcon
          }}
        />
      </TrashedDialogContent>
      <DialogActions>
        {paginated && (<>
          <Pagination
            disabled={paginationDisabled}
            count={pagesCount}
            page={pageNumber}
            onChange={(_, newPageNumber) => setPageNumber(newPageNumber)}
            style={{ margin: '0 auto', flexGrow: 1 }}
          />

          <T variant="body2" component="div" sx={{
            textAlign: 'center',
            alignItems: 'middle',
            marginRight: 2,
            opacity: 0.75,
            fontWeight: 500,
          }}>Total: {count} {count > 1 ? props.labels.groupTypePlural : props.labels.groupType}.</T>
        </>)}
        {!!props.renderDialogActions && props.renderDialogActions(data, refetch)}
      </DialogActions>
    </TrashedDialog>
  );
};
