import React from 'react';
import { EmptyState } from '../EmptyState/EmptyState';
import { useTypedSelector } from '../../hooks';
import { Button } from '@mui/material';
import { signout } from '../../signout';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';


/**
 * @param {object} props
 * @param {string=} props.itemType
 */
export function DatabaseMissing(props) {
  const loggedInEmail = useTypedSelector(store => store.userState.email);
  const itemType = props.itemType || 'space';
  return <div style={{ marginTop: 50 }}>
    <EmptyState
      icon="MISSING"
      title={`Could not load ${itemType}`}
      description={<>
        That {itemType} does not exist or you do not have access to it<br /><br />You are logged in as <b>{loggedInEmail}</b>

        <div style={{ marginTop: 12, fontSize: '14px' }}>
          <Button sx={{ mr: 0.5 }} startIcon={<ExitToAppIcon />} onClick={() => signout()}>Sign out</Button>
          to use a different account.
        </div>
      </>}
    />
  </div>;
}