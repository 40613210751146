import React from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Stack,
  Grid,
  Typography as T,
} from '@mui/material';
import { toast, showPrompt } from '../../message';

import CloseIcon from '@mui/icons-material/Close';
import { useTables, doTableRequest } from '../../hooks/useTables';
import { EmptyState } from '../EmptyState/EmptyState';
import APIToken from './APIToken';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useTypedSelector } from '../../hooks';
import VerificationReminder from '../VerificationReminder/VerificationReminder';


/**
 * @param {object} props
 * @param {object} props.database
 * @param {function} props.onClose
 */
export default function DatabaseAPIKeysDialog(props) {
  let emailVerified = useTypedSelector(state => state.userState.emailVerified);
  return <>
    <Dialog
      open
      onClose={() => props.onClose()}
      maxWidth="md"
      fullWidth
      BackdropProps={{
        style: {
          opacity: 0.2
        }
      }}>
      <DialogTitle
        sx={{
          backgroundColor: 'grey.100',
          pr: 6
        }}
      >
        API tokens in <b>{props.database.name}</b>
        <IconButton
          onClick={() => props.onClose()}
          sx={{
            position: 'absolute',
            transform: 'translate(50%, -50%)',
            right: 28,
            top: 32
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          mx: 3,
          p: 0
        }}
      >
        {emailVerified ? <DatabaseAPIKeysDialogInner
          database={props.database}
          onClose={props.onClose}
        /> : <Box sx={{ pt: 2, pb: 1 }}>
          <VerificationReminder reason="you will not be able to create API tokens" />
        </Box>}
      </DialogContent>
    </Dialog>
  </>;

}


/**
 * @param {object} props
 * @param {object} props.database
 * @param {function} props.onClose
 */
function DatabaseAPIKeysDialogInner(props) {
  const { loading, data, error, refetch } = useTables(`database/tokens/database/${props.database.id}/`);

  async function handleCreate() {
    showPrompt({
      contents: <T>Enter token name:</T>,
      confirmButtonText: 'Create',
      onConfirm: async (name) => {
        try {
          await doTableRequest(`database/tokens/database/${props.database.id}/`, 'POST', { name });
        } catch {
          return;
        }

        await refetch();

        toast('Token created.', {
          intent: 'success'
        });
      }
    });
  }

  function handleView() {
    window.open(`/api-docs/database/${props.database.id}`, '_blank');
  }

  return <>
    <Grid container>
      <Grid item xs={6}>
        <Button
          variant="text"
          sx={{ mt: 1, ml: 3 }}
          onClick={handleView}
        >
          <LibraryBooksIcon sx={{ mr: 1 }} />
          View API documentation
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            variant="contained"
            sx={{ mt: 1, mr: 3, width: '50%' }}
            onClick={handleCreate}
          >Create token</Button>
        </Stack>
      </Grid>
    </Grid>
    {loading ? <LinearProgress sx={{ m: 3 }} /> : null}
    {error ? <Box sx={{ pb: 3 }}>
      <EmptyState
        title="Error loading tokens"
        icon="ERROR"
        description="Please try again later. Contact <support@blaze.today> if this issue persists."
      />
    </Box> : null}
    {!loading && !error && data.length > 0 ?
      data.map(token => <APIToken key={token.id} token={token} database={props.database} refetch={refetch} />)
      : null}
    {!loading && !error && data.length === 0 ? <Box sx={{ pb: 2 }}>
      <EmptyState icon="MISSING" title="No tokens created" description="Create a new token to get started" />
    </Box> : null}
  </>;
}