import React from 'react';
import DataAppInner from './DataAppInner';
import BlazeApp from './BlazeApp';


export default function DataApp() {
  return <BlazeApp>
    <DataAppInner />
  </BlazeApp>;
}
