import React from 'react';
import { Route } from 'react-router-dom';
import './inner_main.css';
import InnerModuleTables from './InnerModuleTables';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import CommonMainPanel from '../MainPanel/CommonMainPanel';
import NotFound from '../NotFound/NotFound';
import PaymentDialog from '../BillingDetails/PaymentDialog';
import { KeyboardShortcutsDialog } from '../KeyboardShortcuts/KeyboardShortcutsDialog';


function InnerMain() {
  return (
    <AuthenticatedRoutes>
      <KeyboardShortcutsDialog />

      <CommonMainPanel>
        <Route exact path="/" component={InnerModuleTables} />
        <Route component={NotFound} />
      </CommonMainPanel>
      <PaymentDialog />
    </AuthenticatedRoutes>
  );
}
export default InnerMain;