import React, { useEffect, useRef, useState } from 'react';
import { TABLES_FRONT_END_DOMAIN } from '../../hooks/useTables';
import { LinearProgress } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { currentIdToken } from '@store';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from '../../hooks';
import useIFrameOrgUpdater from '../../hooks/useIFrameOrgUpdater';

/**
 * @param {object} props
 * @param {string=} props.applicationId
 * @param {React.CSSProperties=} props.style
 * @param {boolean=} props.showCopy
 * @param {boolean=} props.showToolbar
 * @param {function=} props.onMouseDown
 * @param {boolean=} props.notTopFrame
 * @param {() => void=} props.onLoad - this maps to the iframe's onLoad event. The grid may not be fully rendered at this point.
 */
export default function DatabasePublicPageEmbed(props) {
  const {
    publicViewId,
    formId,
  } = /** @type {{ publicViewId: string, formId: string }} */ (useParams());
  const [title, setTitle] = useState(publicViewId ? 'Grid' : 'Form');
  const { applicationId } = props;
  const url = publicViewId ? `${TABLES_FRONT_END_DOMAIN}/public/grid/${publicViewId}`
    : formId ? `${TABLES_FRONT_END_DOMAIN}/public/form/${formId}${window.location.search || ''}`
      : applicationId ? `${TABLES_FRONT_END_DOMAIN}/public/application/${applicationId}?copy=${!!props.showCopy}&toolbar=${!!props.showToolbar}`
        : '';

  return <PublicPageEmbed
    title={title + ' | Data Blaze'}
    allowedOrigin={TABLES_FRONT_END_DOMAIN}
    messageListener={(type, data) => {
      if (type === 'set_title') {
        setTitle(data);
      } else if (type === 'redirect') {
        window.location.assign(data);
      } else if (type === 'mousedown') {
        props.onMouseDown?.();
      }
    }}
    style={props.style}
    notTopFrame={props.notTopFrame}
    url={url}
    onLoad={props.onLoad}
  />;
}

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.allowedOrigin
 * @param {(type: string, data: any) => void=} props.messageListener
 * @param {React.CSSProperties=} props.style
 * @param {boolean=} props.notTopFrame
 * @param {string} props.url
 * @param {() => void=} props.onLoad
 */
export function PublicPageEmbed(props) {
  let [loading, setLoading] = useState(true);
  let [token, setToken] = useState(null);
  let [submitted, setSubmitted] = useState(false);
  const userState = useTypedSelector(store => store.userState);
  const iframeRef = useRef(null);

  useIFrameOrgUpdater(loading, (type, data) => {
    iframeRef.current.contentWindow.postMessage({ type, data }, TABLES_FRONT_END_DOMAIN);
  });

  useEffect(() => {
    if (userState.isLoaded && !token) {
      (async () => {
        if (userState.uid) {
          setToken(await currentIdToken());
        } else {
          setToken('');
        }
      })();
    }
    // eslint-disable-next-line
  }, [userState.isLoaded]);

  useEffect(() => {
    const listener = async (event) => {
      if (event.origin !== props.allowedOrigin) {
        return;
      }

      props.messageListener?.(event.data.type, event.data.data);
    };

    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);

    // eslint-disable-next-line
  }, [loading]);

  return token !== null && <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100dvh',
      ...props.style,
    }}>
    {!props.notTopFrame && <Helmet defer={false}>
      <title>{ props.title }</title>
    </Helmet>}
    { loading && <LinearProgress variant="indeterminate" sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} /> }
    <form
      // We use a form that submits the iframe in order to use POST rather than GET.
      // This is so the token won't be unintentially logged on the server
      target="public_frame"
      action={props.url}
      method="POST"
      ref={(el) => {
        if (el) {
          if (!submitted) {
            setSubmitted(true);
            el.submit();
          }
        }
      }}
      style={{
        display: 'none'
      }}
    >
      <input type="text" name="token" defaultValue={token} />
    </form>
    { /* eslint-disable-next-line */ }
    <iframe
      ref={iframeRef}
      name="public_frame"
      allow="clipboard-read *; clipboard-write *"
      onLoad={() => {
        setLoading(false);
        if (props.onLoad) {
          props.onLoad();
        }
      }}
      src=""
      style={{
        width: '100%',
        flex: 1,
        borderWidth: 0,
      }}
    />
  </div>;
}