import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ReactDOM from 'react-dom';
import NewSnippetInner from '../GroupSelectors/NewSnippetInner';
import { sync } from '../../Sync/syncer';
import { updateGroupDatabaseUsages } from './database_utilities';
import useAppNavigate from '../../hooks/useAppNavigate';

/**
 * @param {object} props
 */
export const SelectFolderDialog = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [snippetConfig, setSnippetConfig] = useState(null);
  const [openCommand, setOpenCommand] = useState(null);
  const navigate = useAppNavigate();

  useImperativeHandle(ref, () => ({
    show: (snippet, openCommand) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setSnippetConfig({
          name: snippet.name,
          shortcut: snippet.shortcut,
          delta: snippet.content.delta.toUint8Array ?
            snippet.content.delta.toUint8Array() :
            snippet.content.delta,
        });
        setIsOpen(true);
        setOpenCommand(openCommand);
      });
    }
  }));

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="lg"
        onClose={() => setIsOpen(false)}
      >
        <DialogContent
          sx={{
            minWidth: {
              xs: 'min(calc(100% - 40px), 220px)',
              sm: '220px'
            },
            padding: 0,
          }}>
          <NewSnippetInner
            quite
            snippetConfig={snippetConfig}
            onCreated={async(createdSnippet) => {
              if (openCommand) {
                localStorage.setItem(`soc-${createdSnippet.snippet_id}`, new Date().toString());
              }
              navigate(`/snippet/${createdSnippet.snippet_id}`, 'TEXT','_blank');
              await updateGroupDatabaseUsages(sync.getSnippetById(createdSnippet.snippet_id).data);
              setIsOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
});
