import { Divider, MenuItem } from '@mui/material';
import { storage } from '../../utilities';
import { usersSettingsRef } from '@store';
import UnfavoriteIcon from '@mui/icons-material/BookmarkRemoveOutlined';
import FavoriteIcon from '@mui/icons-material/BookmarkAddOutlined';
import React from 'react';
import { showPrompt, toast } from '../../message';
import T from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/EditOutlined';
import { doSafeTableRequest } from '../../hooks/useTables';

/**
 * @param {object} props
 * @param {boolean} props.inDatabase
 * @param {() => void} props.onClose
 * @param {string} props.favoritesOptionKey
 * @param {SpaceObjectType|SiteObjectType} props.group
 * @param {string} props.renameEndpoint
 * @param {string} props.itemName
 * @param {() => Promise<any>=} props.refetch
 * @param {(name: string) => void=} props.onRenamed
 */
export default function DatabaseListCommonMenu(props) {
  const isEditor = props.group.user_permission !== 'viewer';

  if (!isEditor && props.inDatabase) {
    return null;
  }

  // TODO PB connectedEditingBlocked

  return <div>
    {isEditor && <MenuItem
      onClick={() => {
        props.onClose();
        showPrompt({
          contents: <T>Enter the new name:</T>,
          confirmButtonText: 'Rename',
          default: props.group.name,
          onConfirm: async (name) => {
            try {
              await doSafeTableRequest(props.renameEndpoint,
                'PATCH',
                {
                  name
                }, { toastMessage: 'An error occurred updating the name.' });
            } catch {
              return;
            }

            if (props.refetch) {
              // in Page Blaze's site editor, no need to refetch, the store reflects live updates
              await props.refetch();
            }

            if (props.onRenamed) {
              props.onRenamed(name);
            }

            toast(`${props.itemName} renamed.`, {
              intent: 'success'
            });
          },
        });
      }}
    ><EditIcon sx={{ opacity: .5, marginRight: 2 }} /> Edit name…</MenuItem>}
    {!props.inDatabase && (props.group.favorited ? <MenuItem
      onClick={() => {
        props.onClose();
        storage.update(usersSettingsRef, {
          [`options.${props.favoritesOptionKey}.${props.group.id}.favorite`]: false,
        });
      }}
    ><UnfavoriteIcon sx={{ opacity: .5, marginRight: 2 }} /> Unfavorite</MenuItem> :
      <MenuItem
        onClick={() => {
          props.onClose();
          storage.update(usersSettingsRef, {
            [`options.${props.favoritesOptionKey}.${props.group.id}.favorite`]: true,
          });
        }}
      ><FavoriteIcon sx={{ opacity: .5, marginRight: 2 }} /> Favorite</MenuItem>
    )}
    <Divider/>
  </div>;
}
