import React from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


export function LeftArrow() {
  const { isFirstItemVisible, scrollPrev, initComplete } = React.useContext(VisibilityContext);
  return (
    <div
      onClick={() => scrollPrev()}
      style={{
        transition: 'opacity 0.2s ease-in-out',
        opacity: isFirstItemVisible || (!initComplete) ? 0 : 1,
        color: 'white',
        cursor: 'pointer',
        borderRight: 'solid 1px rgba(255, 255, 255, .3)',
        height: 30,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <ArrowLeftIcon fontSize="small" />
    </div>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, initComplete } = React.useContext(VisibilityContext);
  return (
    <div
      onClick={() => scrollNext()}
      style={{
        transition: 'opacity 0.2s ease-in-out',
        opacity: isLastItemVisible || (!initComplete) ? 0 : 1,
        color: 'white',
        cursor: 'pointer',
        borderLeft: 'solid 1px rgba(255, 255, 255, .3)',
        height: 30,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <ArrowRightIcon fontSize="small" />
    </div>
  );
}
